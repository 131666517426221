import { FC } from 'react'
import { stringToDate } from '../../../utils/date'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDeleteQuizMutation } from '../../../store/api/quiz/quiz.api'
import { IQuizTableProps } from '../../../store/api/quiz/model'
import RemoveItemModal from '../../../utils/RemoveItemModal/removeItemModal'
import { UseToggleModalHOC } from '../../../hooks/toggleModal'
import { useTranslation } from 'react-i18next'

const QuizTable: FC<IQuizTableProps> = ({ quiz, refetch }): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const navigate = useNavigate()
  const [deleteQuiz] = useDeleteQuizMutation()
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  return (
    <tr className='text-center' key={quiz.id}>
      <td>{quiz.id}</td>
      <td>{quiz.title}</td>
      <td>{quiz.duration}</td>
      <td>{stringToDate(quiz.created_at)}</td>
      <td>
        <span className='text-center align-middle'>
          <ButtonGroup size='sm' className='flex-nowrap'>
            <OverlayTrigger placement='top' overlay={<Tooltip>{tGeneral('edit')}</Tooltip>}>
              <Button onClick={() => navigate(`/quizzes/edit/${quiz.id}`)}>
                {tGeneral('edit')}
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>{tGeneral('delete')}</Tooltip>}>
              <Button onClick={() => toggleModal()}>{tGeneral('delete')}</Button>
            </OverlayTrigger>
          </ButtonGroup>
        </span>
      </td>
      {isModalVisible && (
        <RemoveItemModal
          id={quiz.id}
          deleteItem={deleteQuiz}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </tr>
  )
}
export default QuizTable
