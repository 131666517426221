import { FC } from 'react'
import { Col, Card, Row } from 'react-bootstrap'
import { IGetGlobalStats } from '../../../store/api/deshboard/model'
import { useTranslation } from 'react-i18next'

const DeshboardGlobalStats: FC<{ globalStats: IGetGlobalStats }> = ({
  globalStats
}): JSX.Element => {
  const { t: tDashboardPage } = useTranslation('dashboardPage')

  return (
    <Col lg={6} md={10} sm={12} xl={2}>
      <Card className='overflow-hidden'>
        <Card.Body className='card-body'>
          <Row>
            <div className='col'>
              <h6 className='fs-12'>{globalStats.name}</h6>
              <h3 className='mb-2 number-font'>{globalStats.count}</h3>
              <p className='text-muted mb-0'>{tDashboardPage('lastMonth')}</p>
            </div>
            <div className='col col-auto mt-2'>
              <div className='counter-icon bg-primary-gradient box-shadow-primary brround ms-auto'>
                <i className='fe fe-trending-up text-white mb-5 '></i>
              </div>
            </div>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  )
}

export default DeshboardGlobalStats
