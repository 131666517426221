import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormGroup, Row } from 'react-bootstrap'
import { Params, useNavigate, useParams } from 'react-router-dom'
import DateTimePicker from 'react-datetime-picker'
import { toast } from 'react-toastify'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import {
  useCreateLessonMutation,
  useGetLessonByIdQuery,
  useUpdateLessonMutation
} from '../../../store/api/lesson/lesson.api'
import { IGetLessonData, TCreateLesson } from '../../../store/api/lesson/model'

import MultiSelect from '../../../ui/select/MultiSelect'
import { ISelectData } from '../../../ui/select/model'
import { getImageUrl, showImage } from '../../../utils/imagesFunction'

import { IValidationRequired } from '../../../validation/model'
import { closeValidateRequiredError, isUrlValid } from '../../../validation/validation'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { extractValues } from '../../../utils/extractValues'
import { createSingleSelectData } from '../../../utils/createSingleSelectData'
import { GRADE } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'
import ResourcesFilter from '../../../ui/ResourcesFilter/ResourcesFilter'
import ClassFilter from '../../../ui/ClassFilter/ClassFilter'

const LessonCreate = (): JSX.Element => {
  const navigate = useNavigate()
  const { t: tGeneral } = useTranslation('general')
  const { t: tMessages } = useTranslation('messages')
  const { t: tRequired } = useTranslation('required')
  const { id }: Readonly<Params<string>> = useParams()
  const formRef = React.createRef<HTMLFormElement>()
  const { data, refetch } = useGetLessonByIdQuery(id, {
    skip: !id
  })
  const [createLesson] = useCreateLessonMutation()
  const [updateLesson] = useUpdateLessonMutation()
  const [startValue, setStartValue] = useState<Date | null>(new Date())
  const [isUrlValidation, setIsUrlValidation] = useState<boolean>(true)
  const [errorRequired, setErrorRequired] = useState<IValidationRequired>({})
  const [classesSelect, setClassesSelect] = useState<ISelectData[]>([])
  const [selectedResources, setSelectedResources] = useState<ISelectData[]>([])
  const [imagePreview, setImagePreview] = useState<string>('')
  const [isImageUpload, setIsImageUpload] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<File | null>(null)
  const [isImageErrorMessage, setIsImageErrorMessage] = useState<boolean>(false)
  const [lessonById, setLessonById] = useState<IGetLessonData>({ lang: 'en' } as IGetLessonData)
  const [grades, setGrades] = useState<ISelectData[]>(GRADE)
  const [newGrade, setNewGrade] = useState<string>('')
  const [gradeSelected, setGradeSelected] = useState<ISelectData[]>()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const sendToBeckdata: TCreateLesson = {
      order: formRef?.current.order.value,
      name: formRef?.current.namee.value,
      description: formRef?.current.description.value,
      url: formRef?.current.url.value,
      startDate: startValue,
      duration: formRef?.current.duration.value,
      lang: lessonById.lang
    }
    if (classesSelect?.length > 0) {
      sendToBeckdata.classIds = extractValues(classesSelect)
    }
    if (selectedResources?.length > 0) {
      sendToBeckdata.resourceIds = extractValues(selectedResources)
    }
    if (gradeSelected?.length > 0) {
      sendToBeckdata.grade = extractValues(gradeSelected).join(',')
    }
    if (!Object.values(validationRequaired(sendToBeckdata)).length) {
      setIsUrlValidation(isUrlValid(formRef?.current.url.value))
    }
    setErrorRequired(validationRequaired(sendToBeckdata))
    if (
      !Object.values(validationRequaired(sendToBeckdata)).length &&
      isUrlValid(formRef?.current.url.value)
    ) {
      if (id) {
        if (selectedImage?.type && selectedImage?.type.length > 0) {
          sendToBeckdata.image = selectedImage
        }
        sendToBeckdata.id = String(lessonById.id)
        const res = await updateLesson(sendToBeckdata)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success(tMessages('lessonWasUpdated'))
          navigate('/lesson?page=1&pageSize=20')
        }
      } else {
        if (selectedImage?.type.length > 0) {
          sendToBeckdata.image = selectedImage
          const res = await createLesson(sendToBeckdata)
          if ('error' in res) {
            responseError(res)
          } else {
            toast.success(tMessages('lessonWasCreated'))
            navigate('/lesson?page=1&pageSize=20')
          }
        } else {
          setIsImageErrorMessage(true)
        }
      }
    }
  }

  useEffect(() => {
    if (Object.keys(lessonById).length > 1) {
      setStartValue(new Date(lessonById.startDate))
      if (lessonById.classesLessons) {
        setClassesSelect(
          lessonById.classesLessons.map(clas =>
            createSingleSelectData(clas.class.name, clas.class.id)
          )
        )
      }
      if (lessonById.grade) {
        const gradeArray = lessonById.grade.split(',')

        setGradeSelected(
          gradeArray.map(item => {
            return {
              label: item,
              value: Number(item)
            }
          })
        )
      }
      if (lessonById.resources) {
        setSelectedResources(
          lessonById?.resources.map(resource => createSingleSelectData(resource.name, resource.id))
        )
      }
    }
  }, [lessonById])

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  const selectImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setImagePreview(getImageUrl(e) ?? '')
    setIsImageUpload(true)
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setIsImageErrorMessage(false)
    if (event.target.files && event.target.files.length > 0) {
      setSelectedImage(event.target.files[0])
    }
    selectImage(event)
  }

  const addGrade = (): void => {
    const trimmedGrade = newGrade.trim()

    if (!trimmedGrade) {
      toast.error(tMessages('inputMustNotBeEmpty'))
      return
    }
    const newGradeObject = { label: trimmedGrade, value: Number(trimmedGrade) }

    if (
      grades.some(
        grade => grade.label === newGradeObject.label && grade.value === newGradeObject.value
      )
    ) {
      toast.warning(tMessages('gradeIsAlreadyThere'))
    } else {
      setGrades(prevGrades => [...prevGrades, newGradeObject])
      setNewGrade('')
      toast.success(tMessages('gradeAddedSuccessfully'))
    }
  }
  console.log(data, 'dataByiId')
  useEffect(() => {
    if (data) {
      setLessonById(data)
    }
  }, [data])
  console.log(startValue, 'start')
  return (
    <div className='m-5'>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <Card.Header>
              <div>
                <h2>{tGeneral(lessonById ? 'editLesson' : 'createLesson')}</h2>
              </div>
            </Card.Header>
            <Row>
              <div className='d-flex align-items-center'>
                <p className='my-2 mx-2'>{tGeneral('isArabic')}</p>
                <input
                  checked={lessonById?.lang === 'ar'}
                  type='checkbox'
                  onChange={({ target }) =>
                    setLessonById(prev => ({ ...prev, lang: target.checked ? 'ar' : 'en' }))
                  }
                />
              </div>
            </Row>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark' htmlFor='exampleInputname'>
                        {tGeneral('order')}
                      </label>
                      <input
                        defaultValue={lessonById?.order}
                        name='order'
                        type='number'
                        className='form-control'
                        placeholder={tGeneral('order')}
                        onClick={() =>
                          closeValidateRequiredError('order', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired?.order && (
                      <span className='text-danger'>{errorRequired?.order}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('name')}
                      </label>
                      <input
                        defaultValue={lessonById?.name}
                        name='namee'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('name')}
                        onClick={() =>
                          closeValidateRequiredError('name', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired?.name && (
                      <span className='text-danger'>{errorRequired?.name}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('description')}
                      </label>
                      <textarea
                        defaultValue={lessonById?.description}
                        name='description'
                        className='form-control'
                        placeholder={tGeneral('description')}
                        onClick={() =>
                          closeValidateRequiredError('description', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired?.description && (
                      <span className='text-danger'>{errorRequired?.description}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('duration')}
                      </label>
                      <input
                        defaultValue={lessonById?.duration}
                        name='duration'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('duration')}
                        onClick={() =>
                          closeValidateRequiredError('duration', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired?.duration && (
                      <span className='text-danger'>{errorRequired?.duration}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='file'>
                        {tGeneral('image')}
                      </label>
                      <input
                        id='Image'
                        name='image'
                        type='file'
                        className='form-control'
                        placeholder={tGeneral('image')}
                        accept='image/*'
                        onClick={() =>
                          closeValidateRequiredError('image', errorRequired, setErrorRequired)
                        }
                        onChange={e => handleFileChange(e)}
                      />
                    </FormGroup>
                    {isImageErrorMessage && (
                      <span className='text-danger'>{tRequired('image')}</span>
                    )}
                  </Col>
                  <div>
                    {isImageUpload && showImage(imagePreview)}
                    {lessonById?.imageUrl && !isImageUpload && (
                      <div className='avatar avatar-lg  brround cover-image bg-primary mr-10'>
                        <img
                          style={{ width: '200px' }}
                          src={lessonById?.imageUrl}
                          className='avatar avatar-lg  brround cover-image bg-primary'
                          alt=''
                        />
                      </div>
                    )}
                  </div>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('videoUrl')}
                      </label>
                      <input
                        defaultValue={lessonById?.url}
                        name='url'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('videoUrl')}
                        onClick={() => {
                          setIsUrlValidation(true)
                          closeValidateRequiredError('url', errorRequired, setErrorRequired)
                        }}
                      />
                    </FormGroup>
                    {errorRequired?.url && (
                      <span className='text-danger'>{errorRequired?.url}</span>
                    )}
                    {!isUrlValidation ? (
                      <span className='text-danger'> {tMessages('pleasEnterValidUrl')}</span>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col lg={12} md={6}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('startTime')}
                      </label>
                      <br />
                      <DateTimePicker
                        className='text-dark'
                        amPmAriaLabel='AM / PM'
                        format='y-MM-dd'
                        value={startValue}
                        onChange={value => {
                          console.log(value, 'value')
                          setStartValue(value)
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={12}>
                    <ResourcesFilter
                      selectedResources={selectedResources}
                      setSelectedResources={setSelectedResources}
                    />
                  </Col>
                  <Col xs={6} md={5}>
                    <div className='d-flex align-items-center mt-4 gap-2 mb-3'>
                      <label className='text-dark mb-0' htmlFor='exampleInputname'>
                        {tGeneral('forGrades')}
                      </label>
                      <input
                        type='number'
                        className='form-control w-10'
                        value={newGrade}
                        onChange={({ target }) => setNewGrade(target.value)}
                        style={{ height: '25px' }}
                      />
                      <i
                        onClick={() => addGrade()}
                        className={'side-menu__icon fa fa-plus'}
                        style={{ cursor: 'pointer' }}
                      ></i>
                    </div>
                    <MultiSelect
                      data={grades}
                      editData={gradeSelected}
                      setData={setGradeSelected}
                    />
                  </Col>
                  <Col xs={6} md={12}>
                    <ClassFilter
                      classesSelect={classesSelect}
                      setClassesSelect={setClassesSelect}
                    />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button type='submit' className='btn btn-success mt-1 me-2'>
                  {tGeneral('save')}
                </button>
                <Button
                  onClick={() => navigate('/lesson?page=1&pageSize=20')}
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                >
                  {tGeneral('cancel')}
                </Button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default LessonCreate
