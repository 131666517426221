import { FC, useEffect, useState } from 'react'
import { createSingleSelectData } from '../../utils/createSingleSelectData'
import { useGetQuizQuery } from '../../store/api/quiz/quiz.api'
import { ISelectData } from '../select/model'
import SingleSelect from '../select/SingleSelect'
import { useTranslation } from 'react-i18next'

interface IQuizzesFiltersProps {
  quizzSelected: ISelectData
  setQuizzSelected: React.Dispatch<React.SetStateAction<ISelectData>>
}

const QuizzesFilters: FC<IQuizzesFiltersProps> = ({
  quizzSelected,
  setQuizzSelected
}): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const { data: quizzes, refetch: quizzesRefetch } = useGetQuizQuery()
  const [quizzesData, setQuizzesData] = useState<ISelectData[]>([])

  useEffect(() => {
    if (quizzes?.length > 0) {
      quizzes.forEach(quizItem => {
        setQuizzesData(prev => {
          return [...prev, createSingleSelectData(quizItem.title, quizItem.id)]
        })
      })
    }
  }, [quizzes])

  useEffect(() => {
    quizzesRefetch()
  }, [])

  return (
    <div>
      <label className='text-dark mt-5' htmlFor='exampleInputname'>
        {tGeneral('quiz')}
      </label>
      <SingleSelect
        data={quizzesData}
        editData={quizzSelected.label && quizzSelected}
        setData={setQuizzSelected}
      />
    </div>
  )
}

export default QuizzesFilters
