import React, { useEffect, useState } from 'react'
import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  IChoices,
  IGetWelcomeQuestion,
  TCreateWelcomeQuestion
} from '../../../store/api/welcome/model'
import {
  useCreateWelcomeMutation,
  useGetWelcomeByIdQuery,
  useUpdateWelcomeMutation
} from '../../../store/api/welcome/welcome.api'
import { IValidationRequired } from '../../../validation/model'
import { closeValidateRequiredError } from '../../../validation/validation'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import WelcomeChoiceList from './WelcomeChoiceList/WelcomeChoiceList'
import { DeepDiveMetricsType } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'
import Loading from '../../Loading/Loading'
import SingleSelect from '../../../ui/select/SingleSelect'
import { ISelectData } from '../../../ui/select/model'

const WelcomeCreate = (): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const { t: tRequired } = useTranslation('required')
  const { t: tMessages } = useTranslation('messages')
  const { id }: Readonly<Params<string>> = useParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const formRef = React.createRef<HTMLFormElement>()
  const { data, refetch } = useGetWelcomeByIdQuery(id, {
    skip: !id
  })
  const [isChoiceValid, setIsChoiceValid] = useState<boolean>(false)
  const [choiceData, setChoiceData] = useState<IChoices[]>([])
  const [errorRequired, setErrorRequired] = useState<IValidationRequired>({})
  const [createWelcome] = useCreateWelcomeMutation()
  const [updateWelcome] = useUpdateWelcomeMutation()
  const [welcome, setWelcome] = useState<IGetWelcomeQuestion>()
  const [metricSelect, setMetricSelect] = useState<ISelectData>({
    label: Object.keys(DeepDiveMetricsType)[0]
  } as ISelectData)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    const newWelcomeData: TCreateWelcomeQuestion = {
      question: formRef.current.question.value,
      questionAr: formRef.current.questionAr.value,
      choices: choiceData,
      metric: metricSelect.label
    }

    setErrorRequired(validationRequaired(newWelcomeData))
    if (!Object.values(validationRequaired(newWelcomeData)).length) {
      const res = id
        ? await updateWelcome({ ...newWelcomeData, id })
        : await createWelcome(newWelcomeData)

      if ('error' in res) {
        responseError(res)
      } else {
        toast.success(tMessages(id ? 'questionWasUpdated' : 'Question was created'))
        navigate(-1)
      }
    }
  }

  const handleChangeChoice = (): void => {
    if (formRef.current.text.value.length < 1 || formRef.current.text.value.length < 1) {
      setIsChoiceValid(true)
    } else {
      setIsChoiceValid(false)
      setChoiceData(prev => [
        ...prev,
        {
          text: formRef.current.text.value,
          textAr: formRef.current.textAr.value,
          tag: formRef.current.tag.value,
          isCorrect: formRef.current.isCorrect.checked
        }
      ])
    }
    formRef.current.text.value = ''
    formRef.current.tag.value = ''
    formRef.current.textAr.value = ''
    formRef.current.isCorrect.checked = false
  }
  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  useEffect(() => {
    setWelcome(data)
  }, [data])

  useEffect(() => {
    setChoiceData([])
    if (welcome) {
      welcome.choices?.length > 0 &&
        welcome.choices.forEach(element => {
          setChoiceData(prev => [
            ...prev,
            {
              id: element.id,
              welcomeQuestionId: welcome.id,
              text: element.text,
              textAr: element?.textAr,
              isCorrect: element.isCorrect,
              tag: element?.tag?.tag + ' ' + `(${element?.tag?.tagValue})`
            }
          ])
        })

      setMetricSelect(() => ({ label: welcome.metric }))
    }
  }, [welcome])

  if (pathname.includes('edit') && !welcome) {
    return <Loading />
  }

  return (
    <div>
      <Card.Header>
        <div>
          <h2>{tGeneral(id ? 'editWelcome' : 'createWelcome')}</h2>
        </div>
      </Card.Header>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark' htmlFor='exampleInputname'>
                        {tGeneral('question')}
                      </label>
                      <input
                        defaultValue={welcome?.question}
                        name='question'
                        className='form-control'
                        placeholder={tGeneral('question')}
                        onClick={() =>
                          closeValidateRequiredError('question', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired.question && (
                      <span className='text-danger'>{errorRequired.question}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12} className='mt-4'>
                    <FormGroup>
                      <label className='text-dark' htmlFor='exampleInputname'>
                        {tGeneral('questionAr')}
                      </label>
                      <input
                        defaultValue={welcome?.questionAr}
                        name='questionAr'
                        className='form-control'
                        placeholder={tGeneral('questionAr')}
                        onClick={() =>
                          closeValidateRequiredError('questionAr', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired.questionAr && (
                      <span className='text-danger'>{errorRequired.questionAr}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <Col xs={6} md={12}>
                      <label className='text-dark mt-5'>{tGeneral('choices')}</label>
                    </Col>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark  mt-4' htmlFor='exampleInputname'>
                          {tGeneral('text')}
                        </label>
                        <input
                          onChange={() => setIsChoiceValid(false)}
                          name='text'
                          type='text'
                          className='form-control'
                          placeholder={tGeneral('text')}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark  mt-4' htmlFor='exampleInputname'>
                          {tGeneral('textAr')}
                        </label>
                        <input
                          onChange={() => setIsChoiceValid(false)}
                          name='textAr'
                          type='text'
                          className='form-control'
                          placeholder={tGeneral('textAr')}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={2}>
                      <FormGroup>
                        <label className='text-dark  mt-4' htmlFor='exampleInputname'>
                          {tGeneral('metric')}
                        </label>
                        <SingleSelect
                          data={Object.entries(DeepDiveMetricsType).map(([value], index) => ({
                            label: value,
                            value: index
                          }))}
                          editData={metricSelect}
                          setData={setMetricSelect}
                        />
                      </FormGroup>
                      {errorRequired.metric && (
                        <span className='text-danger'>{errorRequired.metric}</span>
                      )}
                    </Col>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark  mt-4' htmlFor='exampleInputname'>
                          {tGeneral('tag')}
                        </label>
                        <input
                          onChange={() => setIsChoiceValid(false)}
                          name='tag'
                          type='text'
                          className='form-control'
                          placeholder={tGeneral('tag')}
                        />
                      </FormGroup>
                      {isChoiceValid && <span className='text-danger'>{tRequired('choice')}</span>}
                    </Col>
                    <div className='form-check mt-3'>
                      <label className='form-check-label' htmlFor='isCorrect'>
                        {tGeneral('isCorrect')}
                      </label>
                      <input
                        className='form-check-input ms-3'
                        name='isCorrect'
                        type='checkbox'
                        value=''
                        id='isCorrect'
                      />
                    </div>
                    <button onClick={handleChangeChoice} type='button' className='btn btn-info m-2'>
                      {tGeneral('addAnswer')}
                    </button>

                    <WelcomeChoiceList choiceData={choiceData} setChoiceData={setChoiceData} />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button className='btn btn-success mt-1 me-2'>{tGeneral('save')}</button>
                <button
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                  onClick={() => navigate(-1)}
                >
                  {tGeneral('cancel')}
                </button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default WelcomeCreate
