import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import {
  useGetLessonQuery,
  useSendExcelFileLessonsMutation
} from '../../store/api/lesson/lesson.api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LessonTable from './LessonTable/LessonTable'
import Pagination from '../../ui/Pagination/Pagination'
import Loading from '../Loading/Loading'
import SearchFilters from '../../ui/filter/SearchFilters'
import { searchParamsData } from '../../utils/searchParamsData'
import { CustomMessageSuccsesOrError } from '../../utils/CustomMessageSuccsesOrError'
import { DefaultParams, EXCEL_FORMAT, PAGESIZE } from '../../utils/constants'
import { useTranslation } from 'react-i18next'

const Lesson = (): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const { t: tSideBar } = useTranslation('sideBar')
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams(DefaultParams)
  const inputRef = useRef<HTMLInputElement>()
  const [sendExcel] = useSendExcelFileLessonsMutation()
  const {
    isLoading,
    data: lessons,
    refetch
  } = useGetLessonQuery(searchParamsData(searchParams, {}))
  const [importLang, setImportLang] = useState<string>('en')

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const selectedFile = event.target.files?.[0]
    const formData = new FormData()
    if (selectedFile) {
      formData.append('file', selectedFile)
      const res = await sendExcel({ lang: importLang, excelData: formData })
      CustomMessageSuccsesOrError(res)
      refetch()
    }
  }

  useEffect(() => {
    refetch()
  }, [searchParams])

  return (
    <div>
      {isLoading && <Loading />}
      <Row className='mt-5 mb-5'>
        <SearchFilters setQuery={setSearchParams} query={searchParams} showResetButton />
      </Row>
      <Row className='row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={10}>
                <h3 className='card-title mb-1'>{tSideBar('lesson')}</h3>
              </Col>
              <Col className='d-flex'>
                <div className='mx-3'>
                  <input
                    ref={inputRef}
                    onChange={e => handleFileChange(e)}
                    className='d-none'
                    type='file'
                    accept={EXCEL_FORMAT}
                  />
                  <div className='d-flex gap-2 '>
                    <div className='d-flex flex-row align-items-center gap-1'>
                      <span>isArabic</span>
                      <input
                        type='checkbox'
                        onChange={({ target }) => setImportLang(target.checked ? 'ar' : 'en')}
                      />
                    </div>
                    <button
                      type='button'
                      onClick={() => inputRef?.current.click()}
                      className='btn btn-success'
                    >
                      <span>
                        <i className='fe fe-log-in'></i>&nbsp;
                      </span>
                      {tGeneral('import')}
                    </button>
                  </div>
                </div>
                <Button onClick={() => navigate('/lesson/create')}>
                  {tGeneral('createLesson')}
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>{tGeneral('order')}</th>
                      <th>{tGeneral('name')}</th>
                      <th>{tGeneral('photo')}</th>
                      <th>{tGeneral('description')}</th>
                      <th>{tGeneral('duration')}</th>
                      <th>{tGeneral('url')}</th>
                      <th>{tGeneral('startDate')}</th>
                      <th>{tGeneral('createdAt')}</th>
                      <th>{tGeneral('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lessons?.data.length
                      ? lessons?.data.map(lesson => {
                          return <LessonTable key={lesson.id} lesson={lesson} refetch={refetch} />
                        })
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Pagination
              setQuery={setSearchParams}
              numPages={Math.ceil(lessons?.count / PAGESIZE)}
              query={searchParams}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Lesson
