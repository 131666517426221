import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormGroup, Row } from 'react-bootstrap'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  useCreateMeditationMutation,
  useGetMeditationByIdQuery,
  useUpdateMeditationMutation
} from '../../../store/api/meditation/meditation.api'
import { IGetMeditionQuestion, TPostMeditationQuestion } from '../../../store/api/meditation/model'
import { IValidationRequired } from '../../../validation/model'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { useTranslation } from 'react-i18next'

const MeditationCreate = (): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const { t: tMessages } = useTranslation('messages')
  const { t: tRequired } = useTranslation('required')
  const { id }: Readonly<Params<string>> = useParams()
  const [updateMeditation] = useUpdateMeditationMutation()
  const [createMeditation] = useCreateMeditationMutation()
  const [errorRequaired, seterrorRequaired] = useState<IValidationRequired>({})
  const formRef = React.createRef<HTMLFormElement>()
  const navigate = useNavigate()

  const [meditation, setMeditation] = useState<IGetMeditionQuestion>()
  const { data, refetch } = useGetMeditationByIdQuery(id, {
    skip: !id
  })

  useEffect(() => {
    setMeditation(data)
  }, [data])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    const data: TPostMeditationQuestion = {
      name: formRef?.current.namee.value,
      duration: formRef?.current.duration.value,
      source: formRef?.current.source.value,
      url: formRef?.current.url.value,
      file: formRef?.current.audio.files[0]
    }
    seterrorRequaired(validationRequaired(data))
    if (!Object.values(validationRequaired(data)).length) {
      const res = meditation.id
        ? await updateMeditation({ ...data, id: meditation.id })
        : await createMeditation(data)
      if ('error' in res) {
        responseError(res)
      } else {
        toast.success(tMessages(meditation.id ? 'meditationWasUpdated' : 'meditationWasCreated'))
        navigate('/meditation')
      }
    }
  }

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  return (
    <div className='m-5'>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <Card.Header>
              <div>
                <h2>{meditation ? 'Edit Meditation' : 'Create Meditation'} </h2>
              </div>
            </Card.Header>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('name')}
                      </label>
                      <input
                        defaultValue={meditation?.name}
                        name='namee'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('name')}
                        onClick={() => seterrorRequaired({ ...errorRequaired, name: '' })}
                      />
                    </FormGroup>
                    {errorRequaired?.name && (
                      <span className='text-danger'>{tRequired('name')}</span>
                    )}
                  </Col>

                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('duration')}
                      </label>
                      <input
                        defaultValue={meditation?.duration}
                        name='duration'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('duration')}
                        onClick={() => seterrorRequaired({ ...errorRequaired, duration: '' })}
                      />
                    </FormGroup>
                    {errorRequaired?.duration && (
                      <span className='text-danger'>{tRequired('duration')}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('source')}
                      </label>
                      <input
                        defaultValue={meditation?.source}
                        name='source'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('source')}
                        onClick={() => seterrorRequaired({ ...errorRequaired, source: '' })}
                      />
                    </FormGroup>
                    {errorRequaired?.source && (
                      <span className='text-danger'>{tRequired('source')}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('gif')}
                      </label>
                      <input
                        defaultValue={meditation?.url}
                        name='url'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('url')}
                        onClick={() => seterrorRequaired({ ...errorRequaired, url: '' })}
                      />
                    </FormGroup>
                    {errorRequaired?.url && <span className='text-danger'>{tRequired('url')}</span>}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='file'>
                        {tGeneral('audio')}
                      </label>
                      <input
                        id='Image'
                        name='audio'
                        type='file'
                        className='form-control'
                        placeholder='File Url'
                        accept='audio/*'
                        onClick={() => seterrorRequaired({ ...errorRequaired, file: '' })}
                      />
                    </FormGroup>
                    {errorRequaired?.file && (
                      <span className='text-danger'>{tRequired('file')}</span>
                    )}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button type='submit' className='btn btn-success mt-1 me-2'>
                  {tGeneral('save')}
                </button>
                <Button
                  onClick={() => navigate('/meditation')}
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                >
                  {tGeneral('cancel')}
                </Button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default MeditationCreate
