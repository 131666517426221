import React, { FC, useState } from 'react'
import { IQuestionAnswer } from '../../store/api/question/model'
import Modal from '../../ui/modal/Modal'
import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const QuestionAnswerList: FC<IQuestionAnswer> = ({
  multipleChoice,
  setMultipleChoice
}): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const formRef = React.createRef<HTMLFormElement>()
  const [isEditChoice, setIsEditChoice] = useState<boolean>(false)
  const [idChoice, setIdChoice] = useState<number>(null)

  const handleClick = (): void => {
    setMultipleChoice(
      multipleChoice.map((choice, index) => {
        if (index === idChoice) {
          return {
            text: formRef?.current.description.value,
            isCorrect: formRef?.current.correct.checked,
            questionId: choice.questionId,
            id: choice.id
          }
        }
        return choice
      })
    )
    setIsEditChoice(false)
  }

  return (
    <>
      {multipleChoice.length
        ? multipleChoice?.map((choice, index) => (
            <div key={index} className='question-answer-list text-dark mt-3 border'>
              <span>{choice.text}</span>
              <input readOnly type='checkbox' checked={choice.isCorrect} className='m-1' />
              <button
                type='button'
                onClick={() => {
                  setIdChoice(index)
                  setIsEditChoice(true)
                }}
                className='text-info'
              >
                {tGeneral('edit')}
              </button>
              <button
                type='button'
                onClick={() =>
                  setMultipleChoice(prev => {
                    return [...prev.filter(element => element.text !== choice.text)]
                  })
                }
                className='text-danger mx-1'
              >
                X
              </button>
            </div>
          ))
        : null}
      {isEditChoice && (
        <Modal>
          <Card>
            <Card.Header>
              <h4 className='text-dark'>{tGeneral('editChoice')} </h4>
            </Card.Header>
            <form ref={formRef} onSubmit={e => e.preventDefault()}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark  mt-5' htmlFor='exampleInputname'>
                        {tGeneral('description')}
                      </label>
                      <input
                        name='description'
                        className='form-control'
                        defaultValue={multipleChoice.find((e, index) => index === idChoice)?.text}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark  mt-4' htmlFor='exampleInputname'>
                        {tGeneral('isCorrect')}
                      </label>
                      <input
                        name='correct'
                        className='m-2'
                        type='checkbox'
                        defaultChecked={
                          multipleChoice.find((e, index) => index === idChoice)?.isCorrect
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <button onClick={handleClick} type='button' className='btn btn-success mt-1 me-2'>
                  {tGeneral('save')}
                </button>
                <button
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                  onClick={() => setIsEditChoice(false)}
                >
                  {tGeneral('cancel')}
                </button>
              </Card.Footer>
            </form>
          </Card>
        </Modal>
      )}
    </>
  )
}

export default QuestionAnswerList
