import React, { Fragment } from 'react'
import { createRoot } from 'react-dom/client'
import Routes from '../src/route/routes'
import { Provider } from 'react-redux'
import { store } from './store'
import './index.scss'
import { ToastContainer } from 'react-toastify'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import en from './locales/en/en.json'
import ar from './locales/ar/ar.json'
import i18next from 'i18next'
// import { getInCookies } from './utils/localStorageFunc'

// const locale = getInCookies('LOCALE')

i18next.use(initReactI18next).init({
  resources: {
    en: {
      sideBar: en.sideBar,
      general: en.general,
      required: en.required,
      messages: en.messages,
      dashboardPage: en.dashboardPage
    },
    ar: {
      sideBar: ar.sideBar,
      general: ar.general,
      required: ar.required,
      messages: ar.messages,
      dashboardPage: ar.dashboardPage
    }
  },
  lng: 'en',
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false
  }
})

const container: HTMLElement | null = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <Fragment>
        <Routes />
        <ToastContainer />
      </Fragment>
    </I18nextProvider>
  </Provider>
)
