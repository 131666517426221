import { createApi } from '@reduxjs/toolkit/query/react'
import { formDataCreate } from './../../../utils/formDataUpload/formDataUpload'
import { IGetResourceDate, IGetResource, TCreateResource, ISendExcelFileInput } from './model'
import { baseQueryWithReauth } from '../apiSlice'
import { TSendExcelResponsOrError } from '../users/model'
import { IMessageAndStatusCode } from '../admin/model'
import { ISearchParams } from '../../../ui/filter/model'

export const resourceApi = createApi({
  reducerPath: 'resource/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getResource: build.query<IGetResource, ISearchParams | void>({
      query: (params?: ISearchParams) => ({ url: '/admin/resource', params: { ...params } })
    }),

    getResourceById: build.query<IGetResourceDate, number>({
      query: (id: number) => ({ url: `/admin/resource/${id}` })
    }),

    createResource: build.mutation<IMessageAndStatusCode, TCreateResource>({
      query: (data: TCreateResource) => ({
        url: '/admin/resource',
        method: 'POST',
        body: formDataCreate(data)
      })
    }),

    updateResource: build.mutation<IMessageAndStatusCode, TCreateResource>({
      query: (data: TCreateResource) => ({
        url: '/admin/resource',
        method: 'PUT',
        body: formDataCreate(data)
      })
    }),
    sendExcelFile: build.mutation<TSendExcelResponsOrError, ISendExcelFileInput>({
      query: (data: ISendExcelFileInput) => ({
        url: `/admin/import-resources?lang=${data.lang}`,
        method: 'POST',
        body: data.excelData
      })
    }),
    deleteResource: build.mutation<void, number[]>({
      query: (ids: number[]) => ({
        url: '/admin/resource',
        body: { ids },
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetResourceQuery,
  useGetResourceByIdQuery,
  useCreateResourceMutation,
  useUpdateResourceMutation,
  useSendExcelFileMutation,
  useDeleteResourceMutation
} = resourceApi
