import { Fragment, Key, useEffect } from 'react'
import { MENUITEMS } from './SideMenu'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { TItems, TMenuitems } from './model'
import { useTranslation } from 'react-i18next'
// import ChangeLanguage from '../../components/ChangeLanguage/ChangeLanguage'
// import { APP_NAME } from '../../utils/constants'

const Sidebar = (): JSX.Element => {
  const { t: tSideBar } = useTranslation('sideBar')
  const { pathname } = useLocation()

  const setNavActive = (item: TItems): void => {
    MENUITEMS.map((menuItems: TMenuitems) => {
      menuItems.Items.filter((Items: TItems) => {
        if (Items !== item) {
          Items.active = false
        }
        if (Items?.children && Items.children.includes(item)) {
          Items.active = true
        }
        if (Items.children) {
          Items.children.filter((submenuItems: TItems) => {
            if (submenuItems?.children && submenuItems.children.includes(item)) {
              Items.active = true
              submenuItems.active = true
              return true
            } else {
              return false
            }
          })
        }
        return Items
      })
      return menuItems
    })
    item.active = !item.active
  }

  useEffect(() => {
    const currentUrl = pathname.slice(0, -1)
    MENUITEMS.map((items: TMenuitems) => {
      items.Items.filter((Items: TItems) => {
        if (Items.path === currentUrl) {
          setNavActive(Items)
        }
        if (!Items.children) {
          return false
        }
        Items.children.filter((subItems: TItems) => {
          if (subItems.path === currentUrl) {
            setNavActive(subItems)
          }
          if (!subItems.children) {
            return false
          }
          subItems.children.filter((subSubItems: TItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems)
              return true
            } else {
              return false
            }
          })
          return subItems
        })
        return Items
      })
      return items
    })
  }, [])

  const toggletNavActive = (item: TItems): void => {
    if (window.innerWidth <= 991) {
      if (item.type === 'sub') {
        item.active = !item.active
      }
    }
    if (!item.active) {
      MENUITEMS.map((a: TMenuitems) => {
        a.Items.filter((Items: TItems) => {
          if (a.Items.includes(item)) {
            Items.active = false
          }
          if (!Items.children) {
            return false
          }
          Items.children.forEach((b: TItems) => {
            if (Items?.children?.includes(item)) {
              b.active = false
            }
            if (!b.children) {
              return false
            }
            b.children.forEach((c: TItems) => {
              if (b?.children?.includes(item)) {
                c.active = false
              }
            })
          })
          return Items
        })
        return a
      })
    }
    item.active = !item.active
  }
  // Hover effect
  function Onhover(): void {
    if (document.querySelector('.app')?.classList.contains('sidenav-toggled')) {
      document.querySelector('.app')?.classList.add('sidenav-toggled-open')
    }
  }
  function Outhover(): void {
    document.querySelector('.app')?.classList.remove('sidenav-toggled-open')
  }

  return (
    <div className='sticky '>
      <div className='app-sidebar__overlay'></div>
      <aside
        className='app-sidebar overflow overflow-scroll'
        onMouseOver={() => Onhover()}
        onMouseOut={() => Outhover()}
      >
        <div className='main-sidemenu'>
          <div className='slide-left disabled' id='slide-left'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='#7b8191'
              width='24'
              height='24'
              viewBox='0 0 24 24'
            >
              <path d='M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z' />
            </svg>
          </div>
          <div className='slide-leftRTL disabled' id='slide-leftRTL'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='#7b8191'
              width='24'
              height='24'
              viewBox='0 0 24 24'
            >
              <path d='M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z' />
            </svg>
          </div>
          <ul className='side-menu' id='sidebar-main'>
            {/* <li>  <ChangeLanguage /></li> */}
            {MENUITEMS.map((Item: TMenuitems, i: Key) => (
              <Fragment key={i}>
                <li className='sub-category'>
                  <h3>{Item.menutitle}</h3>
                </li>
                {Item.Items.map((menuItem: TItems, i: Key) => (
                  <li className={`slide ${menuItem.active ? 'is-expanded' : ''}`} key={i}>
                    {menuItem.type === 'link' ? (
                      <Link
                        to={menuItem.path ?? ''}
                        className={`side-menu__item ${
                          pathname.includes(menuItem.title.toLowerCase()) ? 'active' : ''
                        }`}
                        onClick={() => {
                          setNavActive(menuItem)
                          toggletNavActive(menuItem)
                        }}
                      >
                        <i className={`side-menu__icon fa fa-${menuItem.icon}`}></i>
                        <span className='side-menu__label'>
                          {tSideBar(menuItem.title.toLowerCase())}
                        </span>
                      </Link>
                    ) : (
                      ''
                    )}
                    {menuItem.type === 'sub' ? (
                      <Link
                        to={menuItem.path ?? ''}
                        className={`side-menu__item ${
                          window.location.pathname.includes(menuItem.title.toLowerCase())
                            ? 'active'
                            : ''
                        }`}
                        onClick={event => {
                          event.preventDefault()
                          setNavActive(menuItem)
                        }}
                      >
                        <i className={`side-menu__icon fa fa-${menuItem.icon}`}></i>
                        <span className='side-menu__label'>
                          {tSideBar(menuItem.title.toLowerCase())}
                        </span>
                        <i className={`${menuItem.background} fa angle fa-angle-right `}></i>
                      </Link>
                    ) : (
                      ''
                    )}
                    {menuItem.children ? (
                      <ul
                        className='slide-menu'
                        style={
                          pathname.includes(menuItem.title.toLowerCase())
                            ? {
                                opacity: 1,
                                transition: 'opacity 500ms ease-in',
                                display: 'block'
                              }
                            : { display: 'none' }
                        }
                      >
                        {menuItem.children.map((childrenItem: TItems, index: Key) => {
                          return (
                            <li key={index}>
                              {childrenItem.type === 'sub' ? (
                                <a
                                  href='javascript'
                                  className='sub-side-menu__item'
                                  onClick={event => {
                                    event.preventDefault()
                                    setNavActive(menuItem)
                                  }}
                                >
                                  <span className='sub-side-menu__label'>
                                    {tSideBar(menuItem.title.toLowerCase())}
                                  </span>
                                  {childrenItem.active ? (
                                    <i className='sub-angle  fa fa-angle-down'></i>
                                  ) : (
                                    <i className='sub-angle fa fa-angle-right'></i>
                                  )}
                                </a>
                              ) : (
                                ''
                              )}
                              {childrenItem.type === 'link' ? (
                                <NavLink
                                  to={childrenItem.path}
                                  className='slide-item'
                                  onClick={() => toggletNavActive(childrenItem)}
                                >
                                  {tSideBar(childrenItem.title.toLowerCase().replaceAll(' ', ''))}
                                </NavLink>
                              ) : (
                                ''
                              )}
                              {childrenItem.children ? (
                                <ul
                                  className='sub-slide-menu'
                                  style={
                                    childrenItem.active ? { display: 'block' } : { display: 'none' }
                                  }
                                >
                                  {childrenItem.children.map(
                                    (childrenSubItem: TItems, key: Key) => (
                                      <li key={key}>
                                        {childrenSubItem.type === 'link' ? (
                                          <NavLink
                                            to={childrenSubItem.path}
                                            className={`${'sub-slide-item'}`}
                                            onClick={() => toggletNavActive(childrenSubItem)}
                                          >
                                            {childrenSubItem.title}
                                          </NavLink>
                                        ) : (
                                          ''
                                        )}
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                ''
                              )}
                            </li>
                          )
                        })}
                      </ul>
                    ) : (
                      ''
                    )}
                  </li>
                ))}
              </Fragment>
            ))}
          </ul>
          <div className='slide-right' id='slide-right'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='#7b8191'
              width='24'
              height='24'
              viewBox='0 0 24 24'
            >
              <path d='M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z' />
            </svg>
          </div>
          <div className='slide-rightRTL' id='slide-rightRTL'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='#7b8191'
              width='24'
              height='24'
              viewBox='0 0 24 24'
            >
              <path d='M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z' />
            </svg>
          </div>
        </div>
      </aside>
    </div>
  )
}

export default Sidebar
