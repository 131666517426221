import {
  Button,
  ButtonGroup,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap'
import { useEffect, useState } from 'react'
import {
  useDeleteCharactersMutation,
  useGetCharactersQuery
} from '../../store/api/ character/character.api'
import { stringToDate } from '../../utils/date'
import CharacterCreate from './CharacterCreate/CharacterCreate'
import Loading from '../Loading/Loading'
import { UseToggleModalHOC } from '../../hooks/toggleModal'
import RemoveItemModal from '../../utils/RemoveItemModal/removeItemModal'
import { useTranslation } from 'react-i18next'

const Character = (): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const [isOpenCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const { data: characters, isLoading, refetch } = useGetCharactersQuery()
  const [deleteCharacter] = useDeleteCharactersMutation()
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  useEffect(() => {
    if (!isOpenCreateModal) {
      refetch()
    }
  }, [isOpenCreateModal])
  return (
    <div className='mt-8'>
      {isLoading && <Loading />}
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={10}>
                <h3 className='card-title mb-1'>{tGeneral('character')}</h3>
              </Col>
              <Col xs={6} md={2}>
                <Button onClick={() => setOpenCreateModal(true)}>
                  {tGeneral('createCharacter')}
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>{tGeneral('id')}</th>
                      <th>{tGeneral('name')}</th>
                      <th>{tGeneral('photo')}</th>
                      <th>{tGeneral('createAt')}</th>
                      <th>{tGeneral('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {characters?.length > 0 &&
                      characters?.map(character => {
                        return (
                          <tr className='text-center' key={character.id}>
                            <td>{character.id}</td>
                            <td>{character.name}</td>
                            <td>
                              <img style={{ width: '100px' }} src={character.imageUrl} alt='' />
                            </td>
                            <td>{stringToDate(character.created_at)}</td>
                            <td>
                              <span className='text-center align-middle'>
                                <ButtonGroup size='sm' className='flex-nowrap'>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip>{tGeneral('delete')}</Tooltip>}
                                  >
                                    <Button onClick={() => toggleModal()}>
                                      {tGeneral('delete')}
                                    </Button>
                                  </OverlayTrigger>
                                </ButtonGroup>
                              </span>
                            </td>
                            {isModalVisible && (
                              <RemoveItemModal
                                id={character.id}
                                deleteItem={deleteCharacter}
                                reload={refetch}
                                onClose={() => toggleModal()}
                              />
                            )}
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {isOpenCreateModal && <CharacterCreate onClose={() => setOpenCreateModal(false)} />}
    </div>
  )
}

export default Character
