import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const Categories: FC<{
  setCategories: React.Dispatch<React.SetStateAction<string[]>>
  index: number
}> = ({ setCategories, index }): JSX.Element => {
  const { t: tSideBar } = useTranslation('sideBar')

  const changeCategory = (value: string, index): void => {
    setCategories(prev => {
      return prev.map((category, i) => {
        if (i === index) {
          category = value
        }
        return category
      })
    })
  }
  const removeItem = (index: number): void => {
    setCategories(prev => [...prev.filter((_, i) => i !== index)])
  }
  return (
    <div className='d-flex align-items-center mb-3'>
      <input
        id='image'
        name='image'
        type='text'
        className='form-control'
        placeholder={tSideBar('category')}
        onChange={e => changeCategory(e.target.value, index)}
      />
      <i onClick={() => removeItem(index)} className={'side-menu__icon fa fa-minus mx-2'}></i>
    </div>
  )
}
export default Categories
