import React, { useEffect, useState } from 'react'
import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import { closeValidateRequiredError } from '../../../validation/validation'
import { IValidationRequired } from '../../../validation/model'
import { ICreateSchool, IGetSchool } from '../../../store/api/school/model'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { Params, useNavigate, useParams } from 'react-router-dom'
import {
  useCreateSchoolMutation,
  useGetSchoolByIdQuery,
  useUpdateSchoolMutation
} from '../../../store/api/school/school.api'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const SchoolCreate = (): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const { t: tMessages } = useTranslation('messages')
  const { id }: Readonly<Params<string>> = useParams()
  const { data, refetch } = useGetSchoolByIdQuery(Number(id), { skip: !id })
  const [schoolById, setSchoolById] = useState<IGetSchool>()

  const [createSchool] = useCreateSchoolMutation()
  const [updateSchool] = useUpdateSchoolMutation()
  const navigate = useNavigate()
  const [errorRequaired, seterrorRequaired] = useState<IValidationRequired>({})
  const formRef = React.createRef<HTMLFormElement>()

  useEffect(() => {
    setSchoolById(data)
  }, [data])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const newData: ICreateSchool = {
      name: formRef?.current.namee.value,
      address: formRef?.current.address.value,
      contactPersonName: formRef?.current.contactPersonName.value,
      position: formRef?.current.position.value,
      email: formRef?.current.email.value,
      phoneNumber: formRef?.current.phoneNumber.value,
      schoolNumber: formRef?.current.schoolNumber.value
    }

    seterrorRequaired(validationRequaired(newData))
    if (!Object.values(validationRequaired(newData)).length) {
      const res = id
        ? await updateSchool({ ...newData, id: Number(id) })
        : await createSchool(newData)
      if ('error' in res) {
        responseError(res)
      } else {
        toast.success(tMessages(id ? 'schoolWasUpdated' : 'schoolWasCreated'))
        navigate(-1)
      }
    }
  }
  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])
  return (
    <div>
      <Card.Header>
        <div>
          <h2>{tGeneral(id ? 'editSchool' : 'createSchool')} </h2>
        </div>
      </Card.Header>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark' htmlFor='nameField'>
                        {tGeneral('name')}
                      </label>
                      <input
                        defaultValue={schoolById?.name}
                        id='nameField'
                        name='namee'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('name')}
                        onClick={() =>
                          closeValidateRequiredError('name', errorRequaired, seterrorRequaired)
                        }
                      />
                    </FormGroup>
                    {errorRequaired.name && (
                      <span className='text-danger'>{errorRequaired.name}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='address'>
                        {tGeneral('address')}
                      </label>
                      <input
                        defaultValue={schoolById?.address}
                        id='address'
                        name='address'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('address')}
                        onClick={() =>
                          closeValidateRequiredError('address', errorRequaired, seterrorRequaired)
                        }
                      />
                    </FormGroup>
                    {errorRequaired.address && (
                      <span className='text-danger'>{errorRequaired.address}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='contactPersonName'>
                        {tGeneral('contactPersonName')}
                      </label>
                      <input
                        defaultValue={schoolById?.contactPersonName}
                        id='contactPersonName'
                        name='contactPersonName'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('contactPersonName')}
                        onClick={() =>
                          closeValidateRequiredError(
                            'contactPersonName',
                            errorRequaired,
                            seterrorRequaired
                          )
                        }
                      />
                    </FormGroup>
                    {errorRequaired.contactPersonName && (
                      <span className='text-danger'>{errorRequaired.contactPersonName}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='position'>
                        {tGeneral('position')}
                      </label>
                      <input
                        defaultValue={schoolById?.position}
                        id='position'
                        name='position'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('position')}
                        onClick={() =>
                          closeValidateRequiredError('position', errorRequaired, seterrorRequaired)
                        }
                      />
                    </FormGroup>
                    {errorRequaired.position && (
                      <span className='text-danger'>{errorRequaired.position}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='email'>
                        {tGeneral('email')}
                      </label>
                      <input
                        defaultValue={schoolById?.email}
                        id='email'
                        name='email'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('email')}
                        onClick={() =>
                          closeValidateRequiredError('email', errorRequaired, seterrorRequaired)
                        }
                      />
                    </FormGroup>
                    {errorRequaired.email && (
                      <span className='text-danger'>{errorRequaired.email}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='phoneNumber'>
                        {tGeneral('phoneNumber')}
                      </label>
                      <input
                        defaultValue={schoolById?.phoneNumber}
                        id='phoneNumber'
                        name='phoneNumber'
                        type='number'
                        className='form-control'
                        placeholder={tGeneral('phoneNumber')}
                        onClick={() =>
                          closeValidateRequiredError(
                            'phoneNumber',
                            errorRequaired,
                            seterrorRequaired
                          )
                        }
                      />
                    </FormGroup>
                    {errorRequaired.phoneNumber && (
                      <span className='text-danger'>{errorRequaired.phoneNumber}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='schoolNumber'>
                        {tGeneral('schoolNumber')}
                      </label>
                      <input
                        defaultValue={schoolById?.name}
                        id='schoolNumber'
                        name='schoolNumber'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('schoolNumber')}
                        onClick={() =>
                          closeValidateRequiredError(
                            'schoolNumber',
                            errorRequaired,
                            seterrorRequaired
                          )
                        }
                      />
                    </FormGroup>
                    {errorRequaired.schoolNumber && (
                      <span className='text-danger'>{errorRequaired.schoolNumber}</span>
                    )}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button className='btn btn-success mt-1 me-2'>{tGeneral('save')}</button>
                <button
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                  onClick={() => navigate(-1)}
                >
                  {tGeneral('cancel')}
                </button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default SchoolCreate
