import { useTranslation } from 'react-i18next'
import MultiSelect from '../select/MultiSelect'
import { FC, useEffect, useState } from 'react'
import { useGetClassQuery } from '../../store/api/class/class.api'
import { selectedDataTake } from '../../utils/selectedDataTake/selectedDataTake'
import { ISelectData } from '../select/model'

interface IClassFilterProps {
  setClassesSelect: React.Dispatch<React.SetStateAction<ISelectData[]>>
  classesSelect: ISelectData[]
}

const ClassFilter: FC<IClassFilterProps> = ({ classesSelect, setClassesSelect }): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const { data: classes, refetch: classesRefetch } = useGetClassQuery({
    pageSize: 100
  })
  const [classesDataSelect, setClassesDataSelect] = useState<ISelectData[]>([])

  useEffect(() => {
    classesRefetch()
  }, [])

  useEffect(() => {
    selectedDataTake(classes?.data, setClassesDataSelect)
  }, [classes])

  return (
    <div>
      <label className='text-dark mt-5' htmlFor='exampleInputname'>
        {tGeneral('assignedClasses')}
      </label>
      <MultiSelect setData={setClassesSelect} data={classesDataSelect} editData={classesSelect} />
    </div>
  )
}

export default ClassFilter
