import { FC } from 'react'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { UseToggleModalHOC } from '../../../hooks/toggleModal'
import { useDeleteClassMutation } from '../../../store/api/class/class.api'
import RemoveItemModal from '../../../utils/RemoveItemModal/removeItemModal'
import { IClassTableProps } from '../../../store/api/class/model'
import { getSectionNames } from '../../../utils/getSectionNames'
import UpdateActivate from '../../../ui/UpdateActivate/UpdateActivate'
import { ACTIVATE_TYPE } from '../../../utils/constants'
import { useTranslation } from 'react-i18next'

const ClassTable: FC<IClassTableProps> = ({
  clas,
  refetch,
  setEditClass,
  setOpenCreateModal
}): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const [deleteClass] = useDeleteClassMutation()
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  return (
    <tr key={clas.id} className='text-center align-middle'>
      <td className='align-middle'>{clas.id}</td>
      <td className='align-middle'>{clas.name}</td>
      <td className='align-middle'>{clas.description}</td>
      <td className='align-middle'>{clas?.school?.name}</td>
      <td className='align-middle'>{getSectionNames(clas.sections)}</td>
      <td className='w-15'>
        <span className='d-flex aline-items-center justify-content-between'>
          <span className='w-15'></span>
          <ButtonGroup size='sm' className='flex-nowrap p-1'>
            <OverlayTrigger placement='top' overlay={<Tooltip>{tGeneral('edit')}</Tooltip>}>
              <Button
                onClick={() => {
                  setOpenCreateModal(true)
                  setEditClass({ ...clas })
                }}
              >
                {tGeneral('edit')}
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>{tGeneral('view')}</Tooltip>}>
              <Button onClick={() => toggleModal()}>{tGeneral('delete')}</Button>
            </OverlayTrigger>
          </ButtonGroup>
          <div className='w-15 d-flex aline-items-center justify-content-between'>
            <UpdateActivate
              checked={clas.isActive}
              id={clas.id}
              type={ACTIVATE_TYPE.Class}
              refetch={refetch}
            />
          </div>
        </span>
      </td>
      {isModalVisible && (
        <RemoveItemModal
          id={clas.id}
          deleteItem={deleteClass}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </tr>
  )
}
export default ClassTable
