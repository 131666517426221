import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { ICreateChoice, IUpdateAndGetQuestion } from '../../../store/api/question/model'
import {
  useCreateQuestionMutation,
  useGetQuestionByIdQuery,
  useUpdateQuestionMutation
} from '../../../store/api/question/question.api'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { closeValidateRequiredError } from '../../../validation/validation'
import { IValidationRequired } from '../../../validation/model'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import QuestionAnswerList from '../QuestionAnswerList'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const QuestionCreate = (): JSX.Element => {
  const { t: tMessages } = useTranslation('messages')
  const { t: tGeneral } = useTranslation('general')
  const { t: tRequired } = useTranslation('required')
  const navigate = useNavigate()
  const { id }: Readonly<Params<string>> = useParams()
  const { data, refetch } = useGetQuestionByIdQuery(id, {
    skip: !id
  })
  const [question, setQuestion] = useState<IUpdateAndGetQuestion>()
  const formRef = React.createRef<HTMLFormElement>()
  const [multipleChoice, setMultipleChoice] = useState<ICreateChoice[]>([])
  const [typeChoice, setTypeChoice] = useState<number>(1)
  const [errorRequaired, seterrorRequaired] = useState<IValidationRequired>({})
  const [createQuestion] = useCreateQuestionMutation()
  const [updateQuestion] = useUpdateQuestionMutation()
  const [isChoiceValid, setIsChoiceValid] = useState<boolean>(false)

  useEffect(() => {
    setQuestion(data)
  }, [data])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const newQuestion: IUpdateAndGetQuestion = {
      text: formRef?.current.description.value,
      type: typeChoice
    }
    if (typeChoice === 1) {
      newQuestion.choices = multipleChoice
    }

    seterrorRequaired(validationRequaired(newQuestion))
    if (!Object.values(validationRequaired(newQuestion)).length) {
      const res = id
        ? updateQuestion({ ...newQuestion, id: Number(id) })
        : createQuestion(newQuestion)
      if ('error' in res) {
        responseError(res)
      } else {
        toast.success(tMessages(id ? 'questionWasUpdated' : 'questionWasCreated'))
        navigate(-1)
      }

      //   if (question?.type) {
      //     newQuestion.id = Number(id)
      //     const res = updateQuestion(newQuestion)
      //     if ('error' in res) {
      //       responseError(res)
      //     } else {
      //       toast.success('Question was updated')
      //       navigate(-1)
      //     }
      //   } else {
      //     const res = await createQuestion(newQuestion)
      //     if ('error' in res) {
      //       responseError(res)
      //     } else {
      //       toast.success('Question was created')
      //       navigate(-1)
      //     }
      //   }
    }
  }

  const handleChangeAsk = (): void => {
    const text: string = formRef.current.ask.value
    const isCorrect: boolean = formRef.current.isCorrect.checked
    if (formRef.current.ask.value.length < 1) {
      setIsChoiceValid(true)
    } else if (formRef.current.ask.value.length > 0) {
      setIsChoiceValid(false)
      setMultipleChoice(prev => {
        return [...prev, { isCorrect, text }]
      })
    }

    formRef.current.isCorrect.checked = false
    formRef.current.ask.value = ''
  }

  useEffect(() => {
    if (question?.type === 2) {
      setTypeChoice(2)
    }
  }, [question?.type])

  useEffect(() => {
    if (id) {
      refetch()
      setMultipleChoice([])
      if (question) {
        question?.choices?.forEach(choice => {
          setMultipleChoice(prev => [
            ...prev,
            {
              text: choice?.text,
              isCorrect: choice?.isCorrect,
              questionId: choice?.questionId,
              id: choice?.id
            }
          ])
        })
      }
    }
  }, [id, question])

  return (
    <div>
      <Card.Header>
        <div>
          <h2>{tGeneral(id ? 'editQuestion' : 'createQuestion')} </h2>
        </div>
      </Card.Header>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark  mt-5' htmlFor='exampleInputname'>
                        {tGeneral('description')}
                      </label>
                      <textarea
                        defaultValue={question?.text}
                        name='description'
                        className='form-control'
                        placeholder={tGeneral('description')}
                        onClick={() =>
                          closeValidateRequiredError('text', errorRequaired, seterrorRequaired)
                        }
                      />
                    </FormGroup>
                    {errorRequaired.text && (
                      <span className='text-danger'>{errorRequaired.text}</span>
                    )}
                  </Col>

                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('type')}
                      </label>
                      <select
                        defaultValue={typeChoice}
                        className='form-control text-dark'
                        name='type'
                        onChange={e => setTypeChoice(Number(e.target.value))}
                      >
                        {typeChoice === 1 && (
                          <>
                            <option value={1}>{tGeneral('multiple-choice')}</option>
                            <option value={2}>{tGeneral('short-answer')}</option>
                          </>
                        )}
                        {typeChoice === 2 && (
                          <>
                            <option value={2}>{tGeneral('short-answer')}</option>
                            <option value={1}>{tGeneral('multiple-choice')}</option>
                          </>
                        )}
                      </select>
                    </FormGroup>
                  </Col>
                  {typeChoice === 1 ? (
                    <Col xs={6} md={12}>
                      <Col xs={6} md={12}>
                        <FormGroup>
                          <label className='text-dark  mt-4' htmlFor='exampleInputname'>
                            {tGeneral('isCorrect')}
                          </label>
                          <input name='isCorrect' type='checkbox' className='m-2' placeholder='' />
                        </FormGroup>
                        <FormGroup>
                          <label className='text-dark  mt-4' htmlFor='exampleInputname'>
                            {tGeneral('answer')}
                          </label>
                          <input
                            onChange={() => setIsChoiceValid(false)}
                            name='ask'
                            type='text'
                            className='form-control'
                            placeholder='Choice'
                          />
                        </FormGroup>
                        {isChoiceValid && (
                          <span className='text-danger'>{tRequired('choice')}</span>
                        )}
                      </Col>
                      <button onClick={handleChangeAsk} type='button' className='btn btn-info m-2'>
                        {tGeneral('addAnswer')}
                      </button>

                      <QuestionAnswerList
                        multipleChoice={multipleChoice}
                        setMultipleChoice={setMultipleChoice}
                      />
                    </Col>
                  ) : null}
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button className='btn btn-success mt-1 me-2'>{tGeneral('save')}</button>
                <button
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                  onClick={() => navigate(-1)}
                >
                  {tGeneral('cancel')}
                </button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default QuestionCreate
