import { FC } from 'react'
import Modal from '../modal/Modal'
import { ModalHeader } from '../modal/ModalHeader'
import { Button, Card } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface IRemoveModalQuestion {
  fetchDeleteByIds: () => Promise<void>
  toggleModal: () => void
}

const RemoveModalQuestion: FC<IRemoveModalQuestion> = ({
  fetchDeleteByIds,
  toggleModal
}): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  return (
    <Modal>
      <div className='py-3'>
        <ModalHeader>
          <div>
            <h2>{tGeneral('areYouSure')}</h2>
          </div>
        </ModalHeader>
        <Card className='text-center shadow-none'>
          <h5>{tGeneral('doYouReallyWantToDelete')}</h5>
        </Card>
        <Card.Footer className='text-center'>
          <Button className='mx-2' onClick={() => toggleModal()}>
            {tGeneral('cancel')}
          </Button>
          <Button className='mx-2' variant='danger' onClick={() => fetchDeleteByIds()}>
            {tGeneral('delete')}
          </Button>
        </Card.Footer>
      </div>
    </Modal>
  )
}

export default RemoveModalQuestion
