import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Button, Card, Col, FormGroup, Row } from 'react-bootstrap'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ISelectData } from '../../../ui/select/model'
import { IValidationRequired } from '../../../validation/model'
import { closeValidateRequiredError } from '../../../validation/validation'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import {
  useCreateLessonPlansMutation,
  useGetLessonPlansByIdQuery,
  useUpdateLessonPlansMutation
} from '../../../store/api/lessonplans/lessonplans.api'
import {
  ILessonPlans,
  ILessonPlansActivities,
  ILessonPlansPOST
} from '../../../store/api/lessonplans/model'
import LessonPlansActivitiesList from '../LessonPlansTable/LessonPlansActivitiesList'
import { createSingleSelectData } from '../../../utils/createSingleSelectData'
import { useTranslation } from 'react-i18next'
import Loading from '../../Loading/Loading'
import LessonsFilter from '../../../ui/LessonsFilter/LessonsFilter'

const LessonCreate = (): JSX.Element => {
  const navigate = useNavigate()
  const { t: tGeneral } = useTranslation('general')
  const { t: tMessages } = useTranslation('messages')
  const { t: tRequired } = useTranslation('required')
  const { id }: Readonly<Params<string>> = useParams()
  const { pathname } = useLocation()
  const { data: LessonPlansById, refetch } = useGetLessonPlansByIdQuery(id, {
    skip: !id
  })
  const formRef = React.createRef<HTMLFormElement>()
  const [createLesson] = useCreateLessonPlansMutation()
  const [updateLesson] = useUpdateLessonPlansMutation()

  const [errorRequired, setErrorRequired] = useState<IValidationRequired>({})

  const [isChoiceValid, setIsChoiceValid] = useState<boolean>(false)
  const [lessonPlansActivities, setLessonPlansActivities] = useState<ILessonPlansActivities[]>([])

  const [lessonSelected, setLessonSelected] = useState<ISelectData>()
  const [lessonPlans, setLessonPlans] = useState<ILessonPlans>()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const pushValue = formRef?.current?.pshe?.value
    const khdaValue = formRef?.current?.khda?.value
    const outcomesValue = formRef?.current?.outcomes?.value
    const overvieweValue = formRef?.current?.overviewe?.value
    const badgeValue = formRef?.current?.badge?.value
    const takeawaysValue = formRef?.current?.takeaways?.value

    const data: ILessonPlansPOST = {
      name: formRef?.current?.namee?.value,
      casel: formRef?.current?.casel?.value,
      isca: formRef?.current?.isca?.value,
      ib: formRef?.current?.ib?.value,
      ...(pushValue ? { pshe: pushValue } : {}),
      ...(khdaValue ? { khda: khdaValue } : {}),
      ...(outcomesValue ? { outcomes: outcomesValue } : {}),
      ...(overvieweValue ? { overviewe: overvieweValue } : {}),
      ...(badgeValue ? { badge: badgeValue } : {}),
      ...(takeawaysValue ? { takeaways: takeawaysValue } : {})
    }

    if (lessonSelected?.value) {
      data.lessonId = lessonSelected?.value
    }
    if (lessonPlansActivities?.length > 0) {
      data.activities = lessonPlansActivities
    }

    setErrorRequired(validationRequaired(data))
    if (!Object.values(validationRequaired(data)).length) {
      const res = id ? await updateLesson({ ...data, id }) : await createLesson(data)
      if ('error' in res) {
        responseError(res)
      } else {
        toast.success(tMessages(id ? 'lessonWasUpdated' : 'lessonWasCreated'))
        navigate('/lessons-plans')
      }
    }
  }
  const handleChangeAsk = (): void => {
    const text: string = formRef?.current?.ask?.value || ''

    if (text.length < 1) {
      setIsChoiceValid(true)
    } else {
      setIsChoiceValid(false)
      setLessonPlansActivities(prev => [...prev, { text }])
    }

    if (formRef?.current?.ask) {
      formRef.current.ask.value = ''
    }
  }

  useLayoutEffect(() => {
    if (lessonPlans?.lesson) {
      setLessonSelected(createSingleSelectData(lessonPlans.lesson.name, lessonPlans.lesson.id))
    }
  }, [lessonPlans])

  useEffect(() => {
    setLessonPlans(LessonPlansById)
  }, [LessonPlansById])

  useEffect(() => {
    if (id) {
      refetch()
      setLessonPlansActivities([])
      LessonPlansById?.activities.forEach(item => {
        setLessonPlansActivities(prev => [
          ...prev,
          {
            text: item?.text,
            id: item?.id
          }
        ])
      })
    }
  }, [id, LessonPlansById])

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  if (!lessonPlans && pathname.includes('edit')) {
    return <Loading />
  }
  return (
    <div className='m-5'>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <Card.Header>
              <div>
                <h2>{tGeneral(lessonPlans ? 'editLessonPlans' : 'createLessonsPlans')} </h2>
              </div>
            </Card.Header>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('name')}
                      </label>
                      <input
                        defaultValue={lessonPlans?.name}
                        name='namee'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('name')}
                        onClick={() =>
                          closeValidateRequiredError('name', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired?.name && (
                      <span className='text-danger'>{errorRequired?.name}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('isca')}
                      </label>
                      <input
                        defaultValue={lessonPlans?.isca}
                        name='isca'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('isca')}
                        onClick={() =>
                          closeValidateRequiredError('isca', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired?.isca && (
                      <span className='text-danger'>{errorRequired?.isca}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('Ib')}
                      </label>
                      <input
                        defaultValue={lessonPlans?.ib}
                        name='ib'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('Ib')}
                        onClick={() =>
                          closeValidateRequiredError('ib', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired?.ib && <span className='text-danger'>{errorRequired?.ib}</span>}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('casel')}
                      </label>
                      <input
                        defaultValue={lessonPlans?.casel}
                        name='casel'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('casel')}
                        onClick={() =>
                          closeValidateRequiredError('casel', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired?.casel && (
                      <span className='text-danger'>{errorRequired?.casel}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('badge')}
                      </label>
                      <input
                        defaultValue={lessonPlans?.badge}
                        name='badge'
                        className='form-control'
                        placeholder={tGeneral('badge')}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('outcomes')}
                      </label>
                      <input
                        defaultValue={lessonPlans?.outcomes}
                        name='outcomes'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('outcomes')}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('overview')}
                      </label>
                      <input
                        defaultValue={lessonPlans?.overview}
                        name='overviewe'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('overview')}
                      />
                    </FormGroup>
                  </Col>

                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('takeaways')}
                      </label>
                      <input
                        defaultValue={lessonPlans?.takeaways}
                        name='takeaways'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('takeaways')}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('PSHE')}
                      </label>
                      <input
                        defaultValue={lessonPlans?.PSHE}
                        name='pshe'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('PSHE')}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('KHDA')}
                      </label>
                      <input
                        defaultValue={lessonPlans?.khda}
                        name='khda'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('KHDA')}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={12}>
                    <LessonsFilter
                      lessonSelected={lessonSelected}
                      setLessonSelected={setLessonSelected}
                    />
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark  mt-4' htmlFor='exampleInputname'>
                        {tGeneral('activities')}
                      </label>
                      <input
                        onChange={() => setIsChoiceValid(false)}
                        name='ask'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('activities')}
                      />
                      {isChoiceValid && (
                        <span className='text-danger'>{tRequired('activity')}</span>
                      )}
                    </FormGroup>
                    <button
                      onClick={handleChangeAsk}
                      type='button'
                      className='btn btn-success mt-2'
                    >
                      {tGeneral('addActivities')}
                    </button>
                    <Col xs={6} md={12} className='mt-2'>
                      <LessonPlansActivitiesList
                        lessonPlansActivities={lessonPlansActivities}
                        setLessonPlansActivities={setLessonPlansActivities}
                      />
                    </Col>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button type='submit' className='btn btn-success mt-1 me-2'>
                  {tGeneral('save')}
                </button>
                <Button
                  onClick={() => navigate('/lessons-plans')}
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                >
                  {tGeneral('cancel')}
                </Button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default LessonCreate
