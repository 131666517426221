import React, { useEffect } from 'react'
import { Dropdown, Navbar, Container } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { deleteInCookies } from '../../utils/localStorageFunc'
import { store } from '../../store'
import { useTranslation } from 'react-i18next'

const Header = (): JSX.Element => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t: tGeneral } = useTranslation('general')
  // leftsidemenu
  const openCloseSidebar = (): void => {
    document.querySelector('.app')?.classList.toggle('sidenav-toggled')
  }

  // responsivesearch
  const responsivesearch = (): void => {
    document.querySelector('.header-search')?.classList.toggle('show')
  }

  const handleSignOut = (): void => {
    deleteInCookies('access_token')
    navigate('/auth/signin')
  }

  useEffect(() => {
    if (location.pathname !== '/users') {
      localStorage.removeItem('filtration')
    }
  }, [location.pathname])

  return (
    <Navbar expand='md' className='app-header header sticky'>
      <Container fluid className='main-container'>
        <div className='d-flex align-items-center'>
          <Link
            aria-label='Hide Sidebar'
            className='app-sidebar__toggle'
            to='#'
            onClick={() => openCloseSidebar()}
          ></Link>
          <div className='responsive-logo'>
            <Link to={'dashboard'} className='header-logo'>
              <img src={''} className='mobile-logo logo-1' alt='logo' />
              <img src={''} className='mobile-logo dark-logo-1' alt='logo' />
            </Link>
          </div>
          <Link className='logo-horizontal ' to={'dashboard'}>
            <img src={''} className='header-brand-img desktop-logo' alt='logo' />
            <img src={''} className='header-brand-img light-logo1' alt='logo' />
          </Link>
          <div className='d-flex order-lg-2 ms-auto header-right-icons'>
            <Navbar.Toggle
              aria-controls='navbarScroll'
              className='navresponsive-toggler d-lg-none ms-auto'
              type='button'
            >
              <span className='navbar-toggler-icon fe fe-more-vertical text-dark'></span>
            </Navbar.Toggle>
            <div className='navbar navbar-collapse responsive-navbar p-0'>
              <Navbar.Collapse className='navbar-collapse' id='navbarSupportedContent-4'>
                <div className='d-flex order-lg-2'>
                  <div className='dropdown d-block d-lg-none'>
                    <Link to='#' className='nav-link icon' onClick={() => responsivesearch()}>
                      <i className='fe fe-search'></i>
                    </Link>
                    <div className='dropdown-menu header-search dropdown-menu-start'>
                      <div className='input-group w-100 p-2 border'>
                        <input type='text' className='form-control' placeholder='Search....' />
                        <div className='input-group-text btn btn-primary'>
                          <i className='fa fa-search' aria-hidden='true'></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Dropdown className=' d-md-flex profile-1'>
                    <Dropdown.Toggle
                      className='nav-link profile leading-none d-flex px-1'
                      variant=''
                    >
                      <span className='bg-primary mt-1 me-2 p-3 text-white border-4 rounded-top rounded-bottom'>
                        {tGeneral('profile')}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      className='dropdown-menu-end dropdown-menu-arrow'
                      style={{ margin: 0 }}
                    >
                      <div className='drop-heading'>
                        <div className='text-center'>
                          <h5 className='text-dark mb-0'>
                            {store.getState().admin.admin.fullName}
                          </h5>
                          <small className='text-muted'>{store.getState().admin.admin.email}</small>
                        </div>
                      </div>
                      <div className='dropdown-divider m-0'></div>
                      <Dropdown.Item href={'/profile'}>
                        <i className='dropdown-icon fe fe-user'></i> {tGeneral('profile')}
                      </Dropdown.Item>
                      <Dropdown.Item href='#' onClick={handleSignOut}>
                        <i className='dropdown-icon fe fe-alert-circle'></i>
                        {tGeneral('signOut')}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Navbar.Collapse>
            </div>
          </div>
        </div>
      </Container>
    </Navbar>
  )
}

export default Header
