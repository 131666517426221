import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { useGetQuizQuery } from '../../store/api/quiz/quiz.api'
import QuizTable from './QuizTable/QuizTable'
import Loading from '../Loading/Loading'
import { useTranslation } from 'react-i18next'

const Quiz = (): JSX.Element => {
  const { t: tSideBar } = useTranslation('sideBar')
  const { t: tGeneral } = useTranslation('general')
  const navigate = useNavigate()
  const { data: quizzes, isLoading, refetch } = useGetQuizQuery()

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div className='mt-8'>
      {isLoading && <Loading />}
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={11}>
                <h3 className='card-title mb-1'>{tSideBar('quizzes')}</h3>
              </Col>
              <Col>
                <Button onClick={() => navigate('/quizzes/create')}>
                  {tGeneral('createQuiz')}
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>{tGeneral('id')}</th>
                      <th>{tGeneral('title')}</th>
                      <th>{tGeneral('duration')}</th>
                      <th>{tGeneral('createdAt')}</th>
                      <th>{tGeneral('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quizzes?.length > 0
                      ? quizzes?.map(quiz => {
                          return <QuizTable key={quiz.id} quiz={quiz} refetch={refetch} />
                        })
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default Quiz
