import { createApi } from '@reduxjs/toolkit/query/react'
import { ISearchParams } from '../../../ui/filter/model'
import { formDataCreate } from '../../../utils/formDataUpload/formDataUpload'
import { IGetLesson, IGetLessonData, ISendExcelFileLessonsInput, TCreateLesson } from './model'
import { baseQueryWithReauth } from '../apiSlice'
import { TSendExcelResponsOrError } from '../users/model'

export const lessonApi = createApi({
  reducerPath: 'lesson/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getLesson: build.query<IGetLesson, ISearchParams>({
      query: (params: ISearchParams) => ({
        url: 'admin/lesson',
        params: { ...params }
      })
    }),
    getLessonById: build.query<IGetLessonData, string>({
      query: (id: string) => ({
        url: `/admin/lesson/${id}`
      })
    }),
    createLesson: build.mutation<void, TCreateLesson>({
      query: (lesson: TCreateLesson) => {
        return {
          url: '/admin/lesson',
          method: 'POST',
          body: formDataCreate(lesson)
        }
      }
    }),
    updateLesson: build.mutation<void, TCreateLesson>({
      query: (lesson: TCreateLesson) => ({
        url: '/admin/lesson',
        method: 'PUT',
        body: formDataCreate(lesson)
      })
    }),
    sendExcelFileLessons: build.mutation<TSendExcelResponsOrError, ISendExcelFileLessonsInput>({
      query: (data: ISendExcelFileLessonsInput) => ({
        url: `/admin/import-lessons?lang=${data.lang}`,
        method: 'POST',
        body: data.excelData
      })
    }),
    deleteLesson: build.mutation<void, string>({
      query: (id: string) => ({
        url: `/admin/lesson/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetLessonQuery,
  useGetLessonByIdQuery,
  useCreateLessonMutation,
  useUpdateLessonMutation,
  useSendExcelFileLessonsMutation,
  useDeleteLessonMutation
} = lessonApi
