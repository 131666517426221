import { Button, Col, Row } from 'react-bootstrap'
import React, { FC, useEffect, useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import 'bootstrap/dist/css/bootstrap.min.css'
import { USER_ROLE, status } from '../../utils/constants'
import { useGetSchoolQuery } from '../../store/api/school/school.api'
import { ISelectData } from '../select/model'
import { selectedDataTake } from '../../utils/selectedDataTake/selectedDataTake'
import { useGetSectionGradesQuery } from '../../store/api/section/section.api'
import { IGetSectionGradesInput } from '../../store/api/section/model'
import { IUsersFiltersProps } from './model'
import { useTranslation } from 'react-i18next'
import SearchFilters from './SearchFilters'

const animatedComponents = makeAnimated()

const UsersFilters: FC<IUsersFiltersProps> = ({ setQuery, query, role }): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const formRef = React.createRef<HTMLFormElement>()
  const [schoolSelectedData, setSchoolSelectedData] = useState<ISelectData[]>([])
  const [sectionSelectedData, setSectionSelectedData] = useState<ISelectData[]>([])
  const [classSelectedData, setClassSelectedData] = useState<ISelectData[]>([])
  const [sectionGradesInput, setSectionGradesInput] = useState<IGetSectionGradesInput | undefined>()

  const { schoolId, classId } = sectionGradesInput || {}
  const shouldSkip = !(schoolId || classId)
  const { data: schools } = useGetSchoolQuery()
  const { data: classAdnSection } = useGetSectionGradesQuery(sectionGradesInput, {
    skip: shouldSkip
  })
  const page = query.get('page')

  const removeSectionAndClass = (): void => {
    query.delete('sectionId')
    query.delete('classId')
    setQuery(query)
  }

  const handleChange = (dataSelect, fideName: string): void => {
    if (fideName === 'schoolId' && dataSelect.value !== sectionGradesInput?.schoolId) {
      setSectionGradesInput(() => ({ schoolId: dataSelect.value }))
      removeSectionAndClass()
      setSectionSelectedData([])
      setClassSelectedData([])
    }
    if (fideName === 'classId' && dataSelect.value !== sectionGradesInput?.classId) {
      removeSectionAndClass()
      setSectionGradesInput(prev => ({ ...prev, classId: dataSelect.value }))
      setSectionSelectedData([])
    }
    query.set(fideName, dataSelect.value)
    if (Number(page) !== 1) {
      query.set('page', '1')
    }
    setQuery(query)
  }

  useEffect(() => {
    if (classAdnSection?.classes) {
      setClassSelectedData([])
    }
    selectedDataTake(classAdnSection?.classes, setClassSelectedData)
    selectedDataTake(classAdnSection?.sections, setSectionSelectedData)
  }, [classAdnSection?.classes])

  useEffect(() => {
    selectedDataTake(schools, setSchoolSelectedData)
  }, [schools])

  const clearInput = (): void => {
    const newQuery = new URLSearchParams()
    newQuery.set('page', '1')
    newQuery.set('pageSize', '20')
    newQuery.set('roleId', role)
    setQuery(newQuery)
    setSectionGradesInput(undefined)
    formRef.current.keyword.value = ''
  }

  return (
    <form ref={formRef}>
      <Row className='mt-5 mb-5'>
        <SearchFilters setQuery={setQuery} query={query} />
        <Col md={6} lg={4} xl={2}>
          <Select
            onChange={selectedOptions => handleChange(selectedOptions as ISelectData, 'schoolId')}
            options={schoolSelectedData}
            components={animatedComponents}
            name='schoolId'
            placeholder={tGeneral('school')}
            value={
              schoolSelectedData.find(option => option.value === Number(query?.get('schoolId'))) ||
              ''
            }
          />
        </Col>
        <Col md={6} lg={4} xl={2}>
          <Select
            onChange={selectedOptions => handleChange(selectedOptions, 'status')}
            options={status}
            components={animatedComponents}
            name='status'
            placeholder={tGeneral('status')}
            value={status.find(option => option.value === Number(query?.get('status'))) || ''}
          />
        </Col>
        {role === USER_ROLE.Student ? (
          <>
            <Col md={6} lg={4} xl={2}>
              <Select
                onChange={selectedOptions => handleChange(selectedOptions, 'classId')}
                options={classSelectedData}
                components={animatedComponents}
                name='classId'
                placeholder={tGeneral('class')}
                value={
                  classSelectedData.find(
                    option => option.value === Number(query?.get('classId'))
                  ) || ''
                }
              />
            </Col>
            <Col md={6} lg={4} xl={2}>
              <Select
                onChange={selectedOptions => handleChange(selectedOptions, 'sectionId')}
                options={sectionSelectedData}
                components={animatedComponents}
                name='sectionId'
                placeholder={tGeneral('section')}
                value={
                  sectionSelectedData.find(
                    option => option.value === Number(query?.get('sectionId'))
                  ) || ''
                }
              />
            </Col>
          </>
        ) : null}
        <Col md={6} xl={2}>
          <Button onClick={() => clearInput()}>{tGeneral('reset')}</Button>
        </Col>
      </Row>
    </form>
  )
}

export default UsersFilters
