import { Button, Col } from 'react-bootstrap'
import React, { FC } from 'react'
import { IFilterData } from './model'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useTranslation } from 'react-i18next'
import useDebounce from '../../hoc/useDebounce'

const SearchFilters: FC<IFilterData> = ({ setQuery, query, showResetButton }): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const formRef = React.createRef<HTMLFormElement>()

  const filtration = useDebounce((event?: React.ChangeEvent<HTMLInputElement>): void => {
    query.set(event.target.name, event.target.value)
    const page = query.get('page')
    if (page !== '1') {
      query.set('page', '1')
    }
    setQuery(query)
  }, 500)

  const clearInput = (): void => {
    formRef.current.keyword.value = ''

    const updatedQuery = new URLSearchParams(query)
    updatedQuery.delete('keyword')
    setQuery(updatedQuery)
  }

  return (
    <>
      <Col md={6} lg={4} xl={2}>
        <form ref={formRef}>
          <input
            defaultValue={query.get('keyword')}
            type='text'
            name='keyword'
            className='bg-white-light form-control'
            placeholder={tGeneral('search')}
            onChange={e => filtration(e)}
          />
        </form>
      </Col>
      {showResetButton ? (
        <Col md={6} xl={6}>
          <Button onClick={() => clearInput()}>{tGeneral('reset')}</Button>
        </Col>
      ) : null}
    </>
  )
}

export default SearchFilters
