import { Card, Row, Col } from 'react-bootstrap'
import PageHeader from '../../layouts/PageHeader/PageHeader'
import { store } from '../../store'
import { stringToDate } from '../../utils/date'
import { useState } from 'react'
import ProfileEdit from './ProfileEdit/ProfileEdit'
import { useTranslation } from 'react-i18next'

const Profile = (): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const [isOpenCreateModal, setOpenCreateModal] = useState<boolean>(false)

  return (
    <div>
      <PageHeader
        componentName={tGeneral('profile')}
        staticName={tGeneral('pages')}
        activePage=''
        createPageUrl={''}
      />
      <Row id='user-profile'>
        <Col lg={12}>
          <Card className=' bg-transparent shadow-none border-0'>
            <Card.Body className=' bg-white'>
              <div className='wideget-user'>
                <Row>
                  <Col lg={12} md={12} xl={10}>
                    <div className='wideget-user-desc d-sm-flex'>
                      <div className='user-wrap m-5'>
                        <h6 className='text-dark mb-3'>
                          {tGeneral('name')}: {store.getState().admin.admin?.fullName}
                        </h6>
                        <h6 className='text-dark mb-3'>
                          {tGeneral('email')}: {stringToDate(store.getState().admin.admin?.email)}
                        </h6>
                      </div>
                    </div>
                  </Col>
                  <Col lg={12} md={12} xl={2}>
                    <div className='wideget-user-desc d-sm-flex'>
                      <button
                        className='btn btn-primary mt-1 me-2'
                        onClick={() => setOpenCreateModal(true)}
                      >
                        {tGeneral('editProfile')}
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {isOpenCreateModal && <ProfileEdit setOpenCreateModal={setOpenCreateModal} />}
    </div>
  )
}

export default Profile
