import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useGetSchoolQuery, useSendExelFileMutation } from '../../store/api/school/school.api'
import SchoolTable from './SchoolTable/SchoolTable'
import { ChangeEvent, useEffect, useRef } from 'react'
import Loading from '../Loading/Loading'
import { CustomMessageSuccsesOrError } from '../../utils/CustomMessageSuccsesOrError'
import { EXCEL_FORMAT } from '../../utils/constants'
import { useTranslation } from 'react-i18next'

const School = (): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const { t: tSideBar } = useTranslation('sideBar')
  const inputRef = useRef<HTMLInputElement>()
  const [sendExel] = useSendExelFileMutation()
  const navigate = useNavigate()
  const { data: schools, refetch, isLoading } = useGetSchoolQuery()

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const selectedFile = event.target.files?.[0]
    const formData = new FormData()
    if (selectedFile) {
      formData.append('file', selectedFile)
      const res = await sendExel(formData)
      CustomMessageSuccsesOrError(res)
      refetch()
    }
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div className='mt-5'>
      {isLoading && <Loading />}
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={10}>
                <h3 className='card-title mb-1'>{tSideBar('schools')}</h3>
              </Col>
              <Col className='d-flex'>
                <div className='mx-3'>
                  <input
                    ref={inputRef}
                    onChange={e => handleFileChange(e)}
                    className='d-none'
                    type='file'
                    accept={EXCEL_FORMAT}
                  />
                  <button
                    type='button'
                    onClick={() => inputRef?.current.click()}
                    className='btn btn-success'
                  >
                    <span>
                      <i className='fe fe-log-in'></i>&nbsp;
                    </span>
                    {tGeneral('import')}
                  </button>
                </div>
                <Button onClick={() => navigate('/schools/create')}>
                  {tGeneral('createSchool')}
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>{tGeneral('id')}</th>
                      <th>{tGeneral('name')}</th>
                      <th>{tGeneral('email')}</th>
                      <th>{tGeneral('contactPersonName')}</th>
                      <th>{tGeneral('address')} </th>
                      <th>{tGeneral('phoneNumber')} </th>
                      <th>{tGeneral('position')} </th>
                      <th>{tGeneral('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {schools?.length > 0
                      ? schools.map(school => (
                          <SchoolTable key={school.id} school={school} refetch={refetch} />
                        ))
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default School
