import React, { FC, useEffect, useState } from 'react'
import { Button, Card, Col, FormGroup, Row } from 'react-bootstrap'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import { toast } from 'react-toastify'
import { useCreateClassMutation, useUpdateClassMutation } from '../../../store/api/class/class.api'
import { IClassModal, ICreateClass } from '../../../store/api/class/model'
import { useGetUsersQuery } from '../../../store/api/users/users.api'
import Modal from '../../../ui/modal/Modal'
import { ModalHeader } from '../../../ui/modal/ModalHeader'
import MultiSelect from '../../../ui/select/MultiSelect'
import { ISelectData } from '../../../ui/select/model'
import { IValidationRequired } from '../../../validation/model'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { USER_ROLE } from '../../../utils/constants'
import { selectedDataTake } from '../../../utils/selectedDataTake/selectedDataTake'
import { useGetSchoolQuery } from '../../../store/api/school/school.api'
import Select from 'react-select'
import { useGetSectionsQuery } from '../../../store/api/section/section.api'
import { extractValues } from '../../../utils/extractValues'
import { createSingleSelectData } from '../../../utils/createSingleSelectData'
import { useTranslation } from 'react-i18next'

const ClassCreate: FC<IClassModal> = ({ onClose, editClass }): JSX.Element => {
  const { t: tMessages } = useTranslation('message')
  const { t: tGeneral } = useTranslation('general')
  const formRef = React.createRef<HTMLFormElement>()
  const [errorRequaired, seterrorRequaired] = useState<IValidationRequired>({})
  const [isRequiredSchool, setIsRequiredSchool] = useState<boolean>(false)

  const [schoolSelected, setSchoolSelected] = useState<ISelectData>()
  const [createClass] = useCreateClassMutation()
  const [updateClass] = useUpdateClassMutation()
  const { data: schools } = useGetSchoolQuery()

  const { data: sections } = useGetSectionsQuery({}, { skip: !editClass })

  const { data: teachers } = useGetUsersQuery(
    {
      pageSize: 200,
      roleId: USER_ROLE.Teacher,
      schoolId: schoolSelected?.value
    },
    { skip: !schoolSelected?.value }
  )
  const { data: students } = useGetUsersQuery(
    {
      pageSize: 200,
      roleId: USER_ROLE.Student,
      schoolId: schoolSelected?.value
    },
    { skip: !schoolSelected?.value }
  )

  const [schoolData, setSchoolData] = useState<ISelectData[]>([])
  const [selectedStudents, setSelectedStudents] = useState<ISelectData[]>([])
  const [dataStudents, setDataStudents] = useState<ISelectData[]>([])
  const [dataTeachers, setDataTeachers] = useState<ISelectData[]>([])
  const [selectedTeachers, setSelectedTeachers] = useState<ISelectData[]>([])
  const [dataSection, setDataSection] = useState<ISelectData[]>([])
  const [selectedSection, setSelectedSection] = useState<ISelectData[]>([])

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void | undefined> => {
    event.preventDefault()
    const classData: ICreateClass = {
      name: formRef?.current.headline.value,
      schoolId: schoolSelected?.value
    }
    if (formRef?.current.description.value.length > 0) {
      classData.description = formRef?.current.description.value
    }
    if (selectedTeachers.length > 0) {
      classData.teacherIds = extractValues(selectedTeachers)
    }
    if (selectedStudents.length > 0) {
      classData.studentIds = extractValues(selectedStudents)
    }
    if (selectedSection.length > 0) {
      classData.sectionIds = extractValues(selectedSection)
    }

    typeof schoolSelected === 'undefined' && setIsRequiredSchool(true)

    seterrorRequaired(validationRequaired(classData))
    if (!Object.values(validationRequaired(classData)).length) {
      if (editClass) {
        classData.id = editClass.id
        const res = await updateClass(classData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success(tMessages('classWasUpdated'))
          onClose()
        }
      } else {
        const res = await createClass(classData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success(tMessages('classWasCreated'))
          onClose()
        }
      }
    }
  }

  useEffect(() => {
    selectedDataTake(schools, setSchoolData)
  }, [schools])

  useEffect(() => {
    if (teachers?.data.length > 0) {
      setDataTeachers(() => {
        return teachers.data.map(item => createSingleSelectData(item.fullName, item.teacher?.id))
      })
    }
  }, [teachers])

  useEffect(() => {
    if (students?.data?.length > 0) {
      setDataStudents(() => {
        return students.data.map(item => createSingleSelectData(item.fullName, item.student.id))
      })
    }
  }, [students])

  useEffect(() => {
    if (sections?.data?.length > 0) {
      setDataSection(() => {
        return sections.data.map(item => createSingleSelectData(item.name, item.id))
      })
    }
  }, [sections])

  useEffect(() => {
    if (editClass) {
      formRef.current.headline.defaultValue = editClass.name
      formRef.current.description.defaultValue = editClass.description

      if (editClass?.schoolId) {
        const findSchool = schools?.find(school => school.id === editClass.schoolId)
        if (findSchool) {
          setSchoolSelected(createSingleSelectData(findSchool.name, findSchool.id))
        }
      }
      if (editClass?.students) {
        setSelectedStudents(() => {
          return editClass.students.map(student =>
            createSingleSelectData(student.fullName, student.id)
          )
        })
      }
      if (editClass?.teachers) {
        setSelectedTeachers(() => {
          return editClass.teachers.map(teacher =>
            createSingleSelectData(teacher.fullName, teacher.id)
          )
        })
      }
      if (editClass?.sections) {
        setSelectedSection(() => {
          return editClass.sections.map(section => createSingleSelectData(section.name, section.id))
        })
      }
    }
  }, [editClass, schools])

  return (
    <Modal>
      <div style={{ maxWidth: '600px', overflow: 'auto' }}>
        <div style={{ overflow: 'hidden' }}>
          <ModalHeader>
            <div>
              <h2>{tGeneral(editClass ? 'editClass' : 'createClass')} </h2>
            </div>
          </ModalHeader>
          <Row>
            <Col lg={12} xl={12} md={12} sm={12}>
              <Card>
                <form ref={formRef} onSubmit={e => handleSubmit(e)}>
                  <Card.Body style={{ maxHeight: 'calc(100vh - 350px)', overflowY: 'auto' }}>
                    <Row>
                      <Col xs={6} md={12}>
                        <FormGroup>
                          <label className='text-dark' htmlFor='exampleInputname'>
                            {tGeneral('name')}
                          </label>
                          <input
                            name='headline'
                            type='text'
                            className='form-control'
                            placeholder={tGeneral('className')}
                            onClick={() => seterrorRequaired({ ...errorRequaired, name: '' })}
                          />
                        </FormGroup>
                        {errorRequaired?.name && (
                          <span className='text-danger'>{errorRequaired?.name}</span>
                        )}
                      </Col>

                      <Col xs={6} md={12}>
                        <label className='text-dark mt-5' htmlFor='exampleInputname'>
                          {tGeneral('school')}
                        </label>
                        <Select
                          options={schoolData}
                          name='singleSelect'
                          value={schoolSelected}
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          menuPortalTarget={document.body}
                          onChange={newValue => {
                            setSchoolSelected(newValue)
                            setSelectedStudents([])
                            setSelectedTeachers([])
                            setIsRequiredSchool(false)
                          }}
                          placeholder={`${tGeneral('select')}...`}
                        />
                        {isRequiredSchool && (
                          <span className='text-danger'>{errorRequaired?.schoolId}</span>
                        )}
                      </Col>
                      {schoolSelected?.value ? (
                        <>
                          <Col xs={6} md={12}>
                            <label className='text-dark mt-5' htmlFor='exampleInputname'>
                              {tGeneral('teacher')}
                            </label>
                            <MultiSelect
                              data={dataTeachers}
                              setData={setSelectedTeachers}
                              editData={selectedTeachers}
                              deleteErrormessage={seterrorRequaired}
                              field='teacherId'
                            />
                          </Col>
                          <Col xs={6} md={12}>
                            <label className='text-dark mt-5' htmlFor='exampleInputname'>
                              {tGeneral('student')}
                            </label>
                            <MultiSelect
                              setData={setSelectedStudents}
                              data={dataStudents}
                              editData={selectedStudents}
                              deleteErrormessage={seterrorRequaired}
                              field='studentIds'
                            />
                          </Col>
                        </>
                      ) : null}
                      {editClass ? (
                        <Col xs={6} md={12}>
                          <label className='text-dark mt-5' htmlFor='exampleInputname'>
                            {tGeneral('sections')}
                          </label>
                          <MultiSelect
                            setData={setSelectedSection}
                            data={dataSection}
                            editData={selectedSection}
                            deleteErrormessage={seterrorRequaired}
                            field='sectionIds'
                          />
                        </Col>
                      ) : null}
                      <Col xs={6} md={12}>
                        <FormGroup>
                          <label className='text-dark mt-5' htmlFor='exampleInputname'>
                            {tGeneral('description')}
                          </label>
                          <textarea
                            name='description'
                            className='form-control'
                            placeholder={tGeneral('description')}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className='text-end'>
                    <button className='btn btn-success mt-1 me-2'> {tGeneral('save')}</button>
                    <Button className='btn btn-danger mt-1 me-2' onClick={onClose}>
                      {tGeneral('cancel')}
                    </Button>
                  </Card.Footer>
                </form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Modal>
  )
}

export default ClassCreate
