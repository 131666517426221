import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useGetCategorysQuery } from '../../store/api/category/category.api'
import CategoryTable from './CategoryTable/CategoryTable'
import { useEffect, useState } from 'react'
import CategoryCreate from './CategoryCreate/CategoryCreate'
import { IGetCategory } from '../../store/api/category/model'
import Loading from '../Loading/Loading'
import { useTranslation } from 'react-i18next'

const Category = (): JSX.Element => {
  const { t: tSideBar } = useTranslation('sideBar')
  const { t: tGeneral } = useTranslation('general')
  const [isOpenCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [editCategory, setEditCategory] = useState<IGetCategory | null>()

  const { data: catagories, isLoading, refetch } = useGetCategorysQuery()

  useEffect(() => {
    if (!isOpenCreateModal) {
      refetch()
    }
  }, [isOpenCreateModal])

  return (
    <div className='mt-8'>
      {isLoading && <Loading />}
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={11}>
                <h3 className='card-title mb-1'>{tSideBar('category')}</h3>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    setOpenCreateModal(true)
                    setEditCategory(null)
                  }}
                >
                  {tGeneral('createCategory')}
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>{tGeneral('id')}</th>
                      <th>{tGeneral('name')}</th>
                      <th>{tGeneral('nameAr')}</th>
                      <th>{tGeneral('createdAt')}</th>
                      <th>{tGeneral('updatedAt')}</th>
                      <th>{tGeneral('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {catagories?.length
                      ? catagories?.map(category => (
                          <CategoryTable
                            key={category.id}
                            category={category}
                            refetch={refetch}
                            setEditCategory={setEditCategory}
                            setOpenCreateModal={setOpenCreateModal}
                          />
                        ))
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {isOpenCreateModal && (
        <CategoryCreate onClose={() => setOpenCreateModal(false)} editCategory={editCategory} />
      )}
    </div>
  )
}
export default Category
