import { Card, Row } from 'react-bootstrap'
import Modal from '../../../ui/modal/Modal'
import { ModalHeader } from '../../../ui/modal/ModalHeader'
import React, { FC, useState } from 'react'
import { useUpdateProfileMutation } from '../../../store/api/profile/profile.api'
import { IEditProfile } from '../../../store/api/profile/model'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { IValidationRequired } from '../../../validation/model'
import { closeValidateRequiredError } from '../../../validation/validation'
import { togglePasswordIcon } from '../../../utils/togglePasswordIcon'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const ProfileEdit: FC<{
  setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ setOpenCreateModal }): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const { t: tMessages } = useTranslation('messages')
  const [updatePassword] = useUpdateProfileMutation()
  const formRef = React.createRef<HTMLFormElement>()
  const [errorRequaired, seterrorRequaired] = useState<IValidationRequired>({})
  const [typeOldPassword, setTypeOldPassword] = useState<string>('password')
  const [typePassword, setTypePassword] = useState<string>('password')
  const [typePasswordConfirm, setTypePasswordConfirm] = useState<string>('password')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const newData: IEditProfile = {
      oldPassword: formRef?.current.oldPassword.value,
      password: formRef?.current.newPassword.value,
      passwordConfirm: formRef?.current.passwordConfirm.value
    }
    seterrorRequaired(validationRequaired(newData))
    if (!Object.values(validationRequaired(newData)).length) {
      const res = await updatePassword(newData)
      if ('error' in res) {
        responseError(res)
      } else {
        toast.success(tMessages('lessonWasCreated'))
        setOpenCreateModal(false)
      }
    }
  }
  return (
    <Modal>
      <Card>
        <ModalHeader>
          <h2 className='modal-title'>{tGeneral('editProfile')}</h2>
        </ModalHeader>
        <form ref={formRef} onSubmit={e => handleSubmit(e)}>
          <Card.Body>
            <Row>
              <div className='wrap-input100 validate-input position-relative mt-4'>
                <label className='text-dark' htmlFor='exampleInputname'>
                  {tGeneral('oldPassword')}
                </label>
                <input
                  className='input100'
                  type={typeOldPassword === 'password' ? 'password' : 'text'}
                  name='oldPassword'
                  placeholder={tGeneral('oldPassword')}
                  onClick={() =>
                    closeValidateRequiredError('oldPassword', errorRequaired, seterrorRequaired)
                  }
                />
                {errorRequaired?.oldPassword && (
                  <span className='text-danger'>{errorRequaired?.oldPassword}</span>
                )}
                <span className='symbol-input100'>
                  <i
                    style={{ left: '20px', top: '40px' }}
                    className='zmdi zmdi-lock  position-absolute'
                    aria-hidden='true'
                  ></i>
                </span>
                <i
                  onClick={() => togglePasswordIcon(setTypeOldPassword, typeOldPassword)}
                  style={{ right: '10px', top: '50px' }}
                  className={`fe ${
                    typeOldPassword === 'password' ? 'fe-eye-off' : 'fe-eye'
                  } position-absolute translate-middle cursor-pointer text-dark`}
                ></i>
              </div>
              <div className='wrap-input100 validate-input position-relative mt-4'>
                <label className='text-dark' htmlFor='exampleInputname'>
                  {tGeneral('newPassword')}
                </label>
                <input
                  className='input100'
                  type={typePassword === 'password' ? 'password' : 'text'}
                  name='newPassword'
                  placeholder={tGeneral('newPassword')}
                  onClick={() =>
                    closeValidateRequiredError('password', errorRequaired, seterrorRequaired)
                  }
                />
                {errorRequaired?.password && (
                  <span className='text-danger'>{errorRequaired?.password}</span>
                )}
                <span className='symbol-input100'>
                  <i
                    style={{ left: '20px', top: '40px' }}
                    className='zmdi zmdi-lock  position-absolute'
                    aria-hidden='true'
                  ></i>
                </span>
                <i
                  onClick={() => togglePasswordIcon(setTypePassword, typePassword)}
                  style={{ right: '10px', top: '50px' }}
                  className={`fe ${
                    typePassword === 'password' ? 'fe-eye-off' : 'fe-eye'
                  } position-absolute  translate-middle cursor-pointer text-dark`}
                ></i>
              </div>
              <div className='wrap-input100 validate-input position-relative mt-4'>
                <label className='text-dark' htmlFor='exampleInputname'>
                  {tGeneral('confirmPassword')}
                </label>
                <input
                  className='input100'
                  type={typePasswordConfirm === 'password' ? 'password' : 'text'}
                  name='passwordConfirm'
                  placeholder='Confirm Password'
                  onClick={() =>
                    closeValidateRequiredError('passwordConfirm', errorRequaired, seterrorRequaired)
                  }
                />
                {errorRequaired?.passwordConfirm && (
                  <span className='text-danger'>{errorRequaired?.passwordConfirm}</span>
                )}
                <span className='symbol-input100'>
                  <i
                    style={{ left: '20px', top: '40px' }}
                    className='zmdi zmdi-lock  position-absolute'
                    aria-hidden='true'
                  ></i>
                </span>
                <i
                  onClick={() => togglePasswordIcon(setTypePasswordConfirm, typePasswordConfirm)}
                  style={{ right: '10px', top: '50px' }}
                  className={`fe ${
                    typePasswordConfirm === 'password' ? 'fe-eye-off' : 'fe-eye'
                  } position-absolute translate-middle cursor-pointer text-dark`}
                ></i>
              </div>
            </Row>
          </Card.Body>
          <Card.Footer>
            <button type='submit' className='btn btn-success mt-1 me-2'>
              {tGeneral('save')}
            </button>
            <button
              type='button'
              className='btn btn-danger mt-1 me-2'
              onClick={() => setOpenCreateModal(false)}
            >
              {tGeneral('cancel')}
            </button>
          </Card.Footer>
        </form>
      </Card>
    </Modal>
  )
}

export default ProfileEdit
