import { FC } from 'react'
import { IQuestionProps } from '../../../store/api/question/model'
import { useDeleteQuestionMutation } from '../../../store/api/question/question.api'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { UseToggleModalHOC } from '../../../hooks/toggleModal'
import RemoveItemModal from '../../../utils/RemoveItemModal/removeItemModal'
import { useTranslation } from 'react-i18next'

const QuestionTable: FC<IQuestionProps> = ({ question, refetch }): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const navigate = useNavigate()
  const [deleteQuestion] = useDeleteQuestionMutation()
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  return (
    <tr key={question.id} className='text-center'>
      <td>{question.id}</td>
      <td>{question.quizId ? question.quizId : null}</td>
      <td>{question.text}</td>
      <td>{question.type === 1 ? 'multiple-choice' : 'short-answer'}</td>
      <td>
        <span className='text-center align-middle'>
          <ButtonGroup size='sm' className='flex-nowrap'>
            <OverlayTrigger placement='top' overlay={<Tooltip>{tGeneral('edit')}</Tooltip>}>
              <Button onClick={() => navigate(`/questions/edit/${question.id}`)}>
                {tGeneral('edit')}
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>View</Tooltip>}>
              <Button onClick={() => navigate(`/questions/${question.id}/choice`)}>
                {tGeneral('view')}
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>{tGeneral('Delete')}</Tooltip>}>
              <Button onClick={() => toggleModal()}>{tGeneral('Delete')}</Button>
            </OverlayTrigger>
          </ButtonGroup>
        </span>
      </td>
      {isModalVisible && (
        <RemoveItemModal
          id={question.id}
          deleteItem={deleteQuestion}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </tr>
  )
}

export default QuestionTable
