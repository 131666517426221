import { useEffect } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useGetMeditationQuery } from '../../store/api/meditation/meditation.api'
import Loading from '../Loading/Loading'
import MeditationTable from './MeditationTable/MeditationTable'
import { useTranslation } from 'react-i18next'

const Meditation = (): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const { t: tSideBar } = useTranslation('sideBar')
  const { data: Meditation, isLoading, refetch } = useGetMeditationQuery()
  const navigate = useNavigate()

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div className='mt-5'>
      {isLoading && <Loading />}
      <Row className='row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={11}>
                <h3 className='card-title mb-1'>{tSideBar('meditation')}</h3>
              </Col>
              <Col className='p-2'>
                <Button onClick={() => navigate('/meditation/create')}>
                  {tGeneral('createMeditation')}
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>{tGeneral('id')}</th>
                      <th>{tGeneral('audio')}</th>
                      <th>{tGeneral('gif')}</th>
                      <th>{tGeneral('name')}</th>
                      <th>{tGeneral('source')}</th>
                      <th>{tGeneral('duration')}</th>
                      <th>{tGeneral('action')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Meditation?.length
                      ? Meditation?.map(mediation => {
                          return (
                            <MeditationTable
                              key={mediation.id}
                              Meditation={mediation}
                              refetch={refetch}
                            />
                          )
                        })
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Meditation
