import { FC } from 'react'
import { useDeleteGroupMutation } from '../../../store/api/group/group.api'
import { IGroupsTable } from '../../../store/api/group/model'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { stringToDate } from '../../../utils/date'
import { UseToggleModalHOC } from '../../../hooks/toggleModal'
import RemoveItemModal from '../../../utils/RemoveItemModal/removeItemModal'
import { useTranslation } from 'react-i18next'

const GroupsTable: FC<IGroupsTable> = ({ group, refetch }): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const navigation = useNavigate()
  const [removeGroup] = useDeleteGroupMutation()
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  return (
    <tr key={group.id} className='text-center'>
      <td>{group.id}</td>
      <td>{group.name}</td>
      <td>{group.description}</td>
      <td>{stringToDate(group.created_at)}</td>
      <td>
        <span className='text-center align-middle'>
          <ButtonGroup size='sm' className='flex-nowrap'>
            <OverlayTrigger placement='top' overlay={<Tooltip>{tGeneral('edit')}</Tooltip>}>
              <Button onClick={() => navigation(`/groups/edit/${group.id}`)}>
                {tGeneral('edit')}
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>{tGeneral('view')}</Tooltip>}>
              <Button onClick={() => toggleModal()}>{tGeneral('delete')}</Button>
            </OverlayTrigger>
          </ButtonGroup>
        </span>
      </td>
      {isModalVisible && (
        <RemoveItemModal
          id={group.id}
          deleteItem={removeGroup}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </tr>
  )
}

export default GroupsTable
