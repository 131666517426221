import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MultiSelect from '../select/MultiSelect'
import { useGetResourceQuery } from '../../store/api/resource/resource.api'
import { selectedDataTake } from '../../utils/selectedDataTake/selectedDataTake'
import { ISelectData } from '../select/model'

interface IResourcesFilterProps {
  setSelectedResources: React.Dispatch<React.SetStateAction<ISelectData[]>>
  selectedResources: ISelectData[]
}

const ResourcesFilter: FC<IResourcesFilterProps> = ({
  selectedResources,
  setSelectedResources
}): JSX.Element => {
  const { t: tSideBar } = useTranslation('sideBar')
  const [resourcePage, setResourcePage] = useState<number>(1)
  const { data: resources, refetch: resourcesRefetch } = useGetResourceQuery({ page: resourcePage })
  const [dataResources, setDataResources] = useState<ISelectData[]>([])

  useEffect(() => {
    resourcesRefetch()
  }, [])

  useEffect(() => {
    selectedDataTake(resources?.date, setDataResources)
  }, [resources])

  return (
    <div>
      <label className='text-dark mt-5' htmlFor='exampleInputname'>
        {tSideBar('resources')}
      </label>
      <MultiSelect
        setData={setSelectedResources}
        data={dataResources}
        editData={selectedResources}
        setPageByScroll={setResourcePage}
        dataCount={resources?.count}
      />
    </div>
  )
}

export default ResourcesFilter
