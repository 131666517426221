import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { ICreateGroup, IGetGroup } from '../../../store/api/group/model'
import {
  useCreateGroupMutation,
  useGetGroupByIdQuery,
  useUpdateGroupMutation
} from '../../../store/api/group/group.api'
import { IValidationRequired } from '../../../validation/model'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { Params, useNavigate, useParams } from 'react-router-dom'
import MultiSelect from '../../../ui/select/MultiSelect'
import { ISelectData } from '../../../ui/select/model'
import { toast } from 'react-toastify'
import { useGetUsersQuery } from '../../../store/api/users/users.api'
import { closeValidateRequiredError } from '../../../validation/validation'
import { extractValues } from '../../../utils/extractValues'
import { createSingleSelectData } from '../../../utils/createSingleSelectData'
import { useTranslation } from 'react-i18next'

const GroupCreate = (): JSX.Element => {
  const { t: tSideBar } = useTranslation('sideBar')
  const { t: tMessages } = useTranslation('messages')
  const { t: tGeneral } = useTranslation('general')
  const { t: tRequired } = useTranslation('required')
  const { id }: Readonly<Params<string>> = useParams()
  const navigation = useNavigate()
  const formRef = React.createRef<HTMLFormElement>()
  const [errorRequaired, seterrorRequaired] = useState<IValidationRequired>({})
  const [dataStudents, setDataStudents] = useState<ISelectData[]>([])
  const [selectedStudents, setSelectedStudets] = useState<ISelectData[]>([])
  const [isRequiredStudent, setIsRequiredStudent] = useState<boolean>(false)

  const { data: users } = useGetUsersQuery({
    pageSize: 200
  })
  const [createGroup] = useCreateGroupMutation()
  const [updateGroup] = useUpdateGroupMutation()
  const [group, setGroup] = useState<IGetGroup>()
  const { data, refetch } = useGetGroupByIdQuery(id, {
    skip: !id
  })

  useEffect(() => {
    setGroup(data)
  }, [data])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const newGroupData: ICreateGroup = {
      name: formRef?.current.namee.value,
      description: formRef?.current.description.value,
      userIds: extractValues(selectedStudents)
    }

    selectedStudents?.length < 1 && setIsRequiredStudent(true)
    seterrorRequaired(validationRequaired(newGroupData))
    if (!Object.values(validationRequaired(newGroupData)).length && selectedStudents?.length > 0) {
      if (id) {
        newGroupData.id = Number(id)
        const res = await updateGroup(newGroupData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success(tMessages('groupWasUpdated'))
          navigation(-1)
        }
      } else {
        const res = await createGroup(newGroupData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success(tMessages('groupWasCreated'))
          navigation(-1)
        }
      }
    }
  }
  useEffect(() => {
    if (group?.users?.length > 0) {
      setSelectedStudets(
        group?.users?.map(student => createSingleSelectData(student.fullName, student.id))
      )
    }
  }, [group])

  useEffect(() => {
    if (users?.data?.length > 0) {
      users?.data?.forEach(user => {
        setDataStudents(prev => {
          return [...prev, createSingleSelectData(user.fullName, user.id)]
        })
      })
    }
  }, [users])

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  useEffect(() => {
    if (selectedStudents?.length > 0) {
      setIsRequiredStudent(false)
    }
  }, [selectedStudents])

  return (
    <div>
      <Card.Header>
        <div>
          <h2>{tGeneral(id ? 'editGroup' : 'createGroup')} </h2>
        </div>
      </Card.Header>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark' htmlFor='exampleInputname'>
                        {tGeneral('name')}
                      </label>
                      <input
                        defaultValue={group?.name}
                        name='namee'
                        type='text'
                        className='form-control'
                        placeholder={tGeneral('name')}
                        onClick={() =>
                          closeValidateRequiredError('name', errorRequaired, seterrorRequaired)
                        }
                      />
                    </FormGroup>
                    {errorRequaired.name && (
                      <span className='text-danger'>{errorRequaired.name}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        {tGeneral('description')}
                      </label>
                      <textarea
                        defaultValue={group?.description}
                        name='description'
                        className='form-control'
                        placeholder={tGeneral('description')}
                        onClick={() =>
                          closeValidateRequiredError(
                            'description',
                            errorRequaired,
                            seterrorRequaired
                          )
                        }
                      />
                    </FormGroup>
                    {errorRequaired.description && (
                      <span className='text-danger'>{errorRequaired.description}</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <label className='text-dark mt-5' htmlFor='exampleInputname'>
                      {tSideBar('users')}
                    </label>
                    <MultiSelect
                      setData={setSelectedStudets}
                      data={dataStudents}
                      editData={selectedStudents}
                    />
                    {isRequiredStudent && (
                      <span className='text-danger'>{tRequired('student')}</span>
                    )}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button className='btn btn-success mt-1 me-2'>{tGeneral('save')}</button>
                <button className='btn btn-danger mt-1 me-2' onClick={() => navigation(-1)}>
                  {tGeneral('cancel')}
                </button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default GroupCreate
