import React, { FC, useState } from 'react'
import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import { IValidationRequired } from '../../../validation/model'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { closeValidateRequiredError } from '../../../validation/validation'
import { TCreateMood, IGetMood } from '../../../store/api/mood/model'
import { useCreateMoodMutation, useUpdateMoodMutation } from '../../../store/api/mood/mood.api'
import { ModalHeader } from '../../../ui/modal/ModalHeader'
import Modal from '../../../ui/modal/Modal'
import { getImageUrl, showImage } from '../../../utils/imagesFunction'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const MoodCreate: FC<{
  editData: IGetMood
  setIsOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ editData, setIsOpenCreateModal }): JSX.Element => {
  const { t: tMessages } = useTranslation('messages')
  const { t: tGeneral } = useTranslation('general')
  const formRef = React.createRef<HTMLFormElement>()
  const [createMood] = useCreateMoodMutation()
  const [updateMood] = useUpdateMoodMutation()

  const [errorRequired, setErrorRequired] = useState<IValidationRequired>({})
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<string>('')
  const [isImageUpload, setIsImageUpload] = useState<boolean>(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const newData: TCreateMood = {
      name: formRef?.current.namee.value,
      file: selectedFile,
      color: formRef?.current.color.value
    }
    setErrorRequired(validationRequaired(newData))

    if (!Object.values(validationRequaired(newData)).length) {
      const res = editData?.id
        ? await updateMood({ ...newData, id: editData.id })
        : await createMood(newData)
      if ('error' in res) {
        responseError(res)
      } else {
        toast.success(tMessages(editData?.id ? 'moodWasUpdated' : 'moodWasCreated'))
        setIsOpenCreateModal(false)
      }
    }
  }

  const selectImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setImagePreview(getImageUrl(e) ?? '')
    setIsImageUpload(true)
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
    selectImage(event)
  }

  return (
    <Modal>
      <div>
        <ModalHeader>
          <div>
            <h2>{tGeneral(editData?.id ? 'editMood' : 'createMood')} </h2>
          </div>
        </ModalHeader>
        <Row>
          <Col lg={12} xl={12} md={12} sm={12}>
            <Card>
              <form ref={formRef} onSubmit={e => handleSubmit(e)}>
                <Card.Body>
                  <Row>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark' htmlFor='name'>
                          {tGeneral('name')}
                        </label>
                        <input
                          defaultValue={editData?.name}
                          id='namee'
                          name='namee'
                          type='text'
                          className='form-control'
                          placeholder={tGeneral('name')}
                          onClick={() =>
                            closeValidateRequiredError('name', errorRequired, setErrorRequired)
                          }
                        />
                      </FormGroup>
                      {errorRequired.name && (
                        <span className='text-danger'>{errorRequired.name}</span>
                      )}
                    </Col>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark mt-5' htmlFor='color'>
                          {tGeneral('color')}
                        </label>
                        <input
                          defaultValue={editData?.color}
                          id='color'
                          name='color'
                          type='text'
                          className='form-control'
                          placeholder={tGeneral('color')}
                          onClick={() =>
                            closeValidateRequiredError('color', errorRequired, setErrorRequired)
                          }
                        />
                      </FormGroup>
                      {errorRequired.color && (
                        <span className='text-danger'>{errorRequired.color}</span>
                      )}
                    </Col>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark mt-5' htmlFor='file'>
                          {tGeneral('file')}
                        </label>
                        <input
                          id='file'
                          name='fileUrl'
                          type='file'
                          className='form-control'
                          accept='image/*'
                          onClick={() =>
                            closeValidateRequiredError('file', errorRequired, setErrorRequired)
                          }
                          onChange={e => handleFileChange(e)}
                        />
                      </FormGroup>
                      {errorRequired.file && (
                        <span className='text-danger'>{errorRequired.file}</span>
                      )}
                    </Col>
                    <div>
                      {isImageUpload && showImage(imagePreview)}
                      {editData?.fileUrl && !isImageUpload && (
                        <div className='avatar avatar-lg  brround cover-image bg-primary mr-10'>
                          <img
                            style={{ width: '200px' }}
                            src={editData?.fileUrl}
                            className='avatar avatar-lg  brround cover-image bg-primary'
                            alt=''
                          />
                        </div>
                      )}
                    </div>
                  </Row>
                </Card.Body>
                <Card.Footer className='text-end'>
                  <button className='btn btn-success mt-1 me-2'>{tGeneral('save')}</button>
                  <button
                    onClick={() => setIsOpenCreateModal(false)}
                    type='button'
                    className='btn btn-danger mt-1 me-2'
                  >
                    {tGeneral('cancel')}
                  </button>
                </Card.Footer>
              </form>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default MoodCreate
