import SingleSelect from '../select/SingleSelect'
import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'
import { ISelectData } from '../select/model'
import { useGetLessonQuery } from '../../store/api/lesson/lesson.api'
import { selectedDataTake } from '../../utils/selectedDataTake/selectedDataTake'

interface ILessonsFilterProps {
  lessonSelected: ISelectData
  setLessonSelected: React.Dispatch<React.SetStateAction<ISelectData>>
}

const LessonsFilter: FC<ILessonsFilterProps> = ({
  lessonSelected,
  setLessonSelected
}): JSX.Element => {
  const { t: tSideBar } = useTranslation('sideBar')
  const { data: lessons, refetch: lessonRefetch } = useGetLessonQuery({
    page: 1,
    pageSize: 200
  })
  const [lessonsData, setLessonsData] = useState<ISelectData[]>([])

  useEffect(() => {
    selectedDataTake(lessons?.data, setLessonsData)
  }, [lessons])

  useEffect(() => {
    lessonRefetch()
  }, [])

  return (
    <div className='w-100'>
      <label className='text-dark mt-5' htmlFor='exampleInputname'>
        {tSideBar('lesson')}
      </label>
      <SingleSelect data={lessonsData} editData={lessonSelected} setData={setLessonSelected} />
    </div>
  )
}

export default LessonsFilter
