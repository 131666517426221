import ReactPaginate from 'react-paginate'
import React, { FC } from 'react'
import './Pagination.css'
import { TPaginationProps } from './model'
import { useTranslation } from 'react-i18next'
const Pagination: FC<TPaginationProps> = ({ setQuery, numPages, query }): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  return (
    <div>
      <ReactPaginate
        className='ReactPaginate'
        pageClassName='item'
        previousClassName='item'
        nextClassName='item'
        breakLabel='...'
        breakClassName='item'
        nextLabel={tGeneral('next')}
        previousLabel={tGeneral('previous')}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={e => {
          query.set('page', String(e.selected + 1))
          setQuery(query)
        }}
        pageCount={numPages as number}
        activeClassName='active'
        disabledClassName='disabled'
      />
    </div>
  )
}
export default Pagination
