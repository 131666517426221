import Row from 'react-bootstrap/esm/Row'
import Loading from '../Loading/Loading'
import Select from 'react-select'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Col, Card, Table, Button } from 'react-bootstrap'
import { searchParamsData } from '../../utils/searchParamsData'
import WellnessCheckinsTable from './WellnessCheckinsTable/WellnessCheckinsTable'
import {
  useGetWellnessCheckinsByFilterQuery,
  useGetWellnessCheckinsQuery
} from '../../store/api/WellnessCheckins/WellnessCheckins.api'
import makeAnimated from 'react-select/animated'
import { IChangeSelect, IGetWellnessCheckinsByFilter } from '../../store/api/WellnessCheckins/model'
import { useTranslation } from 'react-i18next'

const animatedComponents = makeAnimated()

const WellnessCheckins = (): JSX.Element => {
  const { t: tGeneral } = useTranslation('general')
  const { t: tSideBar } = useTranslation('sideBar')
  const [selectData, setSelectData] = useState<string>()
  const dataByFilter = useGetWellnessCheckinsByFilterQuery()
  const [searchParams, setSearchParams] = useSearchParams()
  const { data, isLoading, refetch } = useGetWellnessCheckinsQuery(
    searchParamsData(searchParams, {})
  )

  const handleClickInClear = (): void => {
    if (searchParams.size) {
      setSearchParams({})
      setSelectData('')
    }
  }
  const handleChange = (dataSelect: IChangeSelect): void => {
    setSearchParams({ userId: String(dataSelect?.value) })
    setSelectData(dataSelect.lable)
  }

  useEffect(() => {
    refetch()
  }, [searchParams])

  return (
    <div className='mt-8'>
      {isLoading && <Loading />}
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={10}>
                <h3 className='card-title mb-1'>{tSideBar('wellness-checkins')}</h3>
              </Col>
              <Col>
                <Button onClick={handleClickInClear}>{tGeneral('clear')}</Button>
              </Col>
              <Col md={8} lg={6} xl={2}>
                <Select
                  onChange={(dataSelect: IChangeSelect) => handleChange(dataSelect)}
                  name='FullName'
                  options={dataByFilter.data?.map((item: IGetWellnessCheckinsByFilter) => ({
                    value: item.id,
                    label: item.fullName
                  }))}
                  components={animatedComponents}
                  placeholder={tGeneral('fullName')}
                  value={selectData}
                />
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>{tGeneral('id')}</th>
                      <th>{tGeneral('fullName')}</th>
                      <th>{tGeneral('email')}</th>
                      <th>{tGeneral('wellness')}</th>
                      <th>{tGeneral('role')}</th>
                      <th>{tGeneral('package')}</th>
                      <th>{tGeneral('date')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.length > 0
                      ? data?.map(item => (
                          <WellnessCheckinsTable key={item.id} WellnessCheckins={item} />
                        ))
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default WellnessCheckins
